import React, { Component } from "react";
import environment from "utils/environment";

const asyncComponent = (importComponent) => {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null
      };
      this.mounted = false;
    }

    componentDidMount = async () => {
      this.mounted = true;

      try {
        const { default: component } = await importComponent();
        if (this.mounted) {
          this.setState({ component });
        }
      } catch (e) {
        if (environment === "production") {
          window.location.reload(true);
        } else if (environment === "development") {
          console.error(e);
        }
      }
    };

    componentWillUnmount = () => {
      this.mounted = false;
    };

    render() {
      const C = this.state.component;

      return C ? <C {...this.props} /> : null;
    }
  }

  return AsyncComponent;
};

export default asyncComponent;
