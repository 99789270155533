import React from "react";
import { withStyles } from "@material-ui/core/styles";

import TextField from "components/TextField";

const styles = (theme) => ({});

const Component = ({ classes, name, onNameChange }) => (
  <TextField
    label="Report name"
    margin="normal"
    variant="outlined"
    color="secondary"
    empty={!name}
    fullWidth
    autoFocus
    value={name}
    onChange={({ target }) => onNameChange(target.value)}
  />
);

export default withStyles(styles)(Component);
