import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import { withStyles } from "@material-ui/core/styles";

import sharedStyles from "../styles";
import { useGlobalUI } from "context/globalUI";
import { useAPI, RESOURCES } from "utils/api";
import { useAuth } from "utils/auth";
import Spinner from "components/Spinner";
import AppBar from "components/AppBar";
import Container from "components/Container";
import Typography from "components/Typography";
import Card from "components/Card";
import PageTitle from "components/PageTitle";
import RunReportModal, { Modes as RunReportModalModes } from "components/RunReportModal";
import history from "utils/history";

const styles = (theme) => ({
  ...sharedStyles(theme),
  description: {
    padding: theme.spacing(3),
    margin: theme.spacing(0, 0, 3, 0),
    "& > *": {
      fontSize: 16,
      fontWeight: theme.typography.fontWeightLight,
      letterSpacing: ".1em"
    }
  },
  center: {
    textAlign: "center",
    margin: theme.spacing(0, 0, 3, 0)
  }
});

function Subscription({ classes }) {
  const [loading, setLoading] = React.useState(true);
  const [stripeLoading, setStripeLoading] = React.useState(false);
  const [subscription, setSubscription] = React.useState(null);
  const [showSubscriptionModal, setShowSubscriptionModal] = React.useState(false);

  const api = useAPI();
  const { snackbar } = useGlobalUI();

  async function load() {
    setLoading(true);

    let data;

    try {
      data = await api.callWithAuth("GET", RESOURCES.accountSubscription);
    } catch (e) {
      snackbar.open({ message: e.message, color: "error" });
      return;
    }

    setLoading(false);
    setSubscription(data);
    setShowSubscriptionModal(false);
  }

  React.useEffect(() => {
    load();
  }, []);

  const handleManageClick = async () => {
    if (!subscription) {
      setShowSubscriptionModal(true);
      return;
    }

    let url;
    setStripeLoading(true);

    try {
      const data = await api.callWithAuth("POST", RESOURCES.accountSubscriptionManage, {
        data: { return: "/account/subscription" }
      });
      url = data.url;
    } catch (e) {
      snackbar.open({ message: e.message, color: "error" });
      setStripeLoading(false);
      return;
    }

    window.location.href = url;
  };

  const handleSubscriptionModalClose = () => {
    setShowSubscriptionModal(false);
  };

  const handleSubscriptionModalSuccess = () => {
    load();
    setShowSubscriptionModal(false);
  };

  return (
    <Fragment>
      <AppBar />

      <Container className={classes.container} maxWidth="md">
        <PageTitle crumbs={[<AccountCircleOutlinedIcon />, "My Subscription"]}>My Subscription</PageTitle>

        {loading ? (
          <Spinner />
        ) : (
          <Card>
            <Typography className={classes.description} variant="body1" align="center">
              {subscription
                ? `Your current plan is: ${subscription.name}. Click below to manage your subscription`
                : "You don't currently have an active subscription. Click below to see available plans and pricing!"}
            </Typography>

            <div className={classes.center}>
              <Button color="primary" variant="contained" size="large" onClick={handleManageClick} disabled={stripeLoading}>
                {subscription ? "Manage Subscription" : "See Plans & Pricing"}
              </Button>
            </div>
          </Card>
        )}
      </Container>

      {showSubscriptionModal && (
        <RunReportModal
          mode={RunReportModalModes.BUY_SUBSCRIPTION}
          onCancel={handleSubscriptionModalClose}
          onSuccess={handleSubscriptionModalSuccess}
        />
      )}
    </Fragment>
  );
}

export default withStyles(styles)(Subscription);
