import React from "react";
import { withStyles, Box, Typography } from "@material-ui/core";

const styles = (theme) => ({
  noDataRoot: {
    margin: `0 auto ${theme.spacing(7)}px auto`,
    maxWidth: 600,
    paddingTop: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3)
    }
  },
  text: {
    fontSize: 32,
    fontWeight: theme.typography.fontWeightMefium,
    textAlign: "center"
  },
  svg: {
    width: "55%",
    display: "block",
    margin: "60px auto 0 auto",
    opacity: 0.8
  }
});

function NoData({ text, classes }) {
  return (
    <Box className={classes.noDataRoot}>
      <Typography className={classes.text}>{text}</Typography>
      <img className={classes.svg} src="/assets/no_data.svg" />
    </Box>
  );
}

export default withStyles(styles)(NoData);
