import { useState, useEffect } from "react";
import axios from "axios";

import { useAuth } from "./auth";
import history from "./history";

const { REACT_APP_API_URL } = process.env;

/*
 * Below is a part of "Login As" feature available to admins
 */

const OVERRIDE_USER_ID = "X-Override-User-ID";

export const enterOverrideUserIdMode = (id) => {
  window.sessionStorage.setItem(OVERRIDE_USER_ID, id);

  history.push("/");
  window.location.reload();
};

export const leaveOverrideUserIdMode = () => {
  window.sessionStorage.removeItem(OVERRIDE_USER_ID);

  history.push("/");
  window.location.reload();
};

export const overriddenUserId = window.sessionStorage.getItem(OVERRIDE_USER_ID);

if (overriddenUserId) {
  axios.defaults.headers.common[OVERRIDE_USER_ID] = overriddenUserId;
}

/*
 * End of "Login As" feature implementation
 */

export const RESOURCES = {
  userInfo: "/auth/userInfo",
  login: "/auth/login",
  forgotPassword: "/auth/forgotPassword",
  resetPassword: "/auth/resetPassword",
  changePassword: "/auth/changePassword",
  changeEmail: "/auth/changeEmail",
  changeEmailConfirm: "/auth/changeEmailConfirm",
  verifyInviteCode: "/auth/verifyInviteCode",
  verifyEmail: "/auth/verifyEmail",
  register: "/auth/register",
  userFeedback: "/utils/feedback",

  prefsNotifications: "/account/preferences/notifications",

  properties: "/properties",
  propertiesWithReports: "/properties/withReports",
  propertiesContacts: (propertyId, id) => `/properties/${propertyId}/owner/contacts${id ? `/${id}` : ""}`,

  attributes: "/attributes",
  attributeHelperText: (id) => `/attributes/${id}/helperText`,
  geocode: "/utils/geocode",
  territories: "/utils/territories",
  propertyReportPDF: (propertyId, reportId, token) =>
    `${API_URL}${RESOURCES.properties}/${propertyId}/reports/${reportId}/pdf?access_token=${token}`,
  propertyReportCSV: (propertyId, reportId, token) =>
    `${API_URL}${RESOURCES.properties}/${propertyId}/reports/${reportId}/csv?access_token=${token}`,

  accountContacts: "/account/contacts",
  accountProfile: "/account/profile",
  accountSubscription: "/account/subscription",
  accountSubscriptionPrices: "/account/subscription/prices",
  accountSubscriptionManage: "/account/subscription/manage",
  accountSubscriptionCheckout: "/account/subscription/checkout",
  accountSubscriptionPay: "/account/subscription/pay",

  media: "/media",

  retailers: "/retailers",
  retailersWithReports: "/retailers/withReports",
  retailersContacts: (retailerId, id) => `/retailers/${retailerId}/contacts${id ? `/${id}` : ""}`,

  retailerReportPDF: (retailerId, reportId, token) =>
    `${API_URL}${RESOURCES.retailers}/${retailerId}/reports/${reportId}/pdf?access_token=${token}`,
  retailerReportCSV: (retailerId, reportId, token) =>
    `${API_URL}${RESOURCES.retailers}/${retailerId}/reports/${reportId}/csv?access_token=${token}`,

  reports: "/reports",

  users: "/admin/users",
  usersQuery: "/admin/users/query",
  usersInvite: "/admin/users/invite",
  notifyUsersInvited: "/admin/users/invite/notify",
  inviteCodes: "/admin/inviteCodes",
  inviteCodesWithUsers: "/admin/inviteCodes/withUsers",
  adminProperties: "/admin/properties",
  adminPropertiesQuery: "/admin/properties/query",
  adminPropertiesContacts: (id) => `/admin/properties/${id}/contacts`,
  adminPropertiesUsers: (id) => `/admin/properties/${id}/users`,
  adminRetailers: "/admin/retailers",
  adminRetailersQuery: "/admin/retailers/query",
  adminRetailersUsers: (id) => `/admin/retailers/${id}/users`,
  adminRetailersContacts: (id) => `/admin/retailers/${id}/contacts`
};

export const API_URL = REACT_APP_API_URL;

export class APIError extends Error {
  constructor(message, status, data) {
    super(message);
    this.status = status;
    this.data = data;
  }
}

export const call = async (method, url, token, axiosOptions) => {
  let options = {
    method,
    url: `${REACT_APP_API_URL}${url}`,
    ...axiosOptions
  };

  if (token) {
    options = {
      ...options,
      headers: {
        Authorization: `Bearer ${token}`
      },
      withCredentials: true
    };
  }

  let data;

  try {
    const result = await axios(options);
    data = result.data;
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error) {
      throw new APIError(e.response.data.error.message, e.response.status, e.response.data.error.data);
    }

    throw new APIError(
      "Something went wrong with the connection. Please try again later.",
      e.response ? e.response.status : null
    );
  }

  return data;
};

export function useAPI() {
  const auth = useAuth();

  const callWithAuth = async (method, url, axiosOptions) => {
    const token = auth.getToken();
    let result;

    try {
      result = await call(method, url, token, axiosOptions);
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        auth.logout();
        return;
      }

      throw e;
    }

    return result;
  };

  return {
    callWithAuth
  };
}
