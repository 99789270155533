import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { useAuth } from "utils/auth";
import { useGlobalUI } from "context/globalUI";
import Modal from "components/Modal";
import Spinner from "components/Spinner";

const styles = (theme) => ({
  textField: {
    marginTop: theme.spacing(2)
  }
});

const ForgotPasswordModal = ({ classes, onCancel, defaultEmail = "", onSuccess }) => {
  const [email, setEmail] = React.useState(defaultEmail);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const auth = useAuth();
  const { snackbar } = useGlobalUI();

  const handleEmailChange = ({ target }) => setEmail(target.value);

  const handleConfirm = async () => {
    if (!email) {
      return;
    }

    setError(null);
    setLoading(true);

    try {
      await auth.forgotPassword(email);
    } catch (e) {
      setError(e.message);
      return;
    } finally {
      setLoading(false);
    }

    snackbar.open({ message: "Success! An email containing further instructions will arrive momentarily.", color: "success" });
    onSuccess();
  };

  return (
    <Modal
      open={true}
      title="Forgot Password"
      actions={
        <>
          {loading && <Spinner size={20} />}

          <Button variant="text" onClick={onCancel} disabled={loading}>
            Cancel
          </Button>

          <Button variant="text" color="primary" disabled={loading || !email} onClick={handleConfirm}>
            Send
          </Button>
        </>
      }
    >
      <Typography paragraph>
        Provide your email address below. An email containing instructions to complete the 'Password Reset' process will be sent
        to the address you provide.
      </Typography>

      <TextField
        autoFocus
        variant="outlined"
        label="Email Address"
        className={classes.textField}
        fullWidth
        margin="normal"
        disabled={loading}
        value={email}
        onChange={handleEmailChange}
        required
      />

      {error && <Typography color="error">{error}</Typography>}
    </Modal>
  );
};

export default withStyles(styles)(ForgotPasswordModal);
