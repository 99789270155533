import React from "react";
import { Route, Redirect } from "react-router-dom";

import Spinner from "components/Spinner";
import { useAuth } from "utils/auth";

export default ({ component: Component, ...rest }) => {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.loading ? <Spinner fullScreen size={24} /> : auth.user ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
};
