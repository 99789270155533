import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import Container from "components/Container";
import AppBar from "components/AppBar";

const styles = (theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    marginTop: "-10%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-100px"
    }
  },
  titleText: {
    fontSize: 40,
    fontWeight: 300,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  imgContainer: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  image: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "70%"
    },
    maxWidth: "100%",
    [theme.breakpoints.between(600, 800)]: {
      maxWidth: "100%"
    }
  },
  contentText: {
    fontSize: 26,
    fontWeight: 300,
    marginBottom: 0,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  ctaButtoncontainer: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  ctaButton: {
    fontSize: 18,
    fontWeight: 400,
    width: 255,
    height: 56,
    margin: theme.spacing(6, 0, 0, 4),
    textTransform: "capitalize",
    [theme.breakpoints.between(300, 595)]: {
      margin: 0
    }
  }
});

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { children, classes } = this.props;

    if (this.state.error) {
      return (
        <>
          <Container className={classes.root}>
            <Grid container spacing={3}>
              <Grid item md={3} sm={2} xs={false}></Grid>
              <Grid item md={9} sm={10} xs={12} className={classes.titleText}>
                Uh oh...
                <br />
                Something went wrong.
                <br />
                <p className={classes.contentText}>
                  Something went wrong with the connection, or the code, or server, or database, or . . . you get the idea.
                </p>
              </Grid>

              <Grid item sm={6} xs={12} align="right" className={classes.imgContainer}>
                <img src="/assets/server-down.svg" className={classes.image} />
              </Grid>
              <Grid item sm={6} xs={12} align="left" className={classes.ctaButtoncontainer}>
                <a href="/">
                  <Button color="primary" variant="contained" className={classes.ctaButton}>
                    RealPropX Home
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Container>
        </>
      );
    } else {
      // no error
      return children;
    }
  }
}

export default withStyles(styles)(ErrorBoundary);
