import React from "react";
import ReactDOM from "react-dom";

import { unregister } from "./utils/serviceWorker";
import { sentryInit } from "./utils/sentry";
import Main from "./Main";

import "mapbox-gl/dist/mapbox-gl.css";

sentryInit();

ReactDOM.render(<Main />, document.getElementById("root"));
unregister();
