import React, { Fragment } from "react";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DoneIcon from "@material-ui/icons/Done";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";

import Spinner from "components/Spinner";
import { useGlobalUI } from "context/globalUI";

const styles = (theme) => ({
  subscription: {
    background: theme.palette.primary.main,
    color: "#FFF",
    padding: theme.spacing(2, 3)
  },
  subscriptionName: {
    fontWeight: theme.typography.fontWeightMedium
  },
  flex: theme.helpers.flex,
  circle: {
    height: 36,
    width: 36,
    borderRadius: "50%",
    background: "#FFF",
    lineHeight: "36px",
    textAlign: "center",
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(2)
  },
  accordionRoot: {
    "&::before": {
      display: "none"
    }
  },
  plansMobile: {},
  panel: {
    marginBottom: theme.spacing(2),
    borderRadius: 4
  },
  plans: {
    ...theme.helpers.flex,
    justifyContent: "center"
  },
  plan: {
    minWidth: "33%",
    flexBasis: "33%",
    border: "1px solid #AAA",
    marginLeft: -1,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      width: "auto"
    }
  },
  firstPlan: {
    borderTop: "6px solid #459F85"
  },
  secondPlan: {
    zIndex: 2,
    borderColor: "#0C1C6C",
    borderTop: "6px solid #0C1C6C",
    [theme.breakpoints.down("xs")]: {
      zIndex: 0
    }
  },
  thirdPlan: {
    borderTop: "6px solid #E59038"
  },
  highlightedPlan: {
    marginTop: theme.spacing(-4),
    marginBottom: theme.spacing(-4),
    "& $planBestDeal": {
      display: "block"
    },
    "& $planButton": {
      marginBottom: theme.spacing(4)
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      marginBottom: theme.spacing(2),
      "& $planButton": {
        marginBottom: 0
      }
    }
  },
  planHeading: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(2)
  },
  planHeadingMobile: {
    fontSize: 22,
    fontWeight: theme.typography.fontWeightMedium
  },
  planPrice: {
    fontSize: 22,
    "& > small": {
      fontSize: "x-small"
    }
  },
  planPriceMobile: {
    fontSize: 20,
    "& > small": {
      fontSize: "x-small"
    }
  },
  planBestDeal: {
    color: "#777",
    display: "none",
    height: 16
  },
  headerPriceContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  planDescriptionRow: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    margin: theme.spacing(2, 1),
    "& > :first-child": {
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.down("xs")]: {
      lineHeight: 1
    }
  },
  planButton: {
    margin: theme.spacing(1, 0, 2),
    borderColor: theme.palette.border.light,
    textTransform: "none"
  },
  oneTime: {
    ...theme.helpers.flex,
    justifyContent: "space-around",
    border: "1px solid #AAA",
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1)
    }
  },
  oneTimeButton: {
    borderColor: "#AAA",
    color: "#777",
    textTransform: "none",
    "&:hover": {
      color: theme.palette.secondary.main
    }
  },
  oneTimeHeading: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("xs")]: {
      lineHeight: 1
    }
  },
  oneTimePrice: {
    fontSize: 18
  },
  oneTimeDescription: {
    [theme.breakpoints.down("xs")]: {
      lineHeight: 1
    }
  }
});

const Component = ({ classes, loading, checkoutLoading, subscription, recurringPrices, onetimePrices, onSelectPrice }) => {
  const [expanded, setExpanded] = React.useState(false);

  const { deviceScreen } = useGlobalUI();

  const handlePanels = (idx) => (event, isExpanded) => {
    setExpanded(isExpanded ? idx : false);
  };

  const handleSelectPrice = (price) => () => {
    onSelectPrice(price);
  };

  const currentSubscriptionPrice = recurringPrices.find(({ id }) => id === subscription.price_id);

  let prices = recurringPrices
    .sort((a, b) => a.price - b.price)
    .map((price, idx) => {
      const planClasses = [
        [classes.plan, classes.firstPlan],
        [classes.plan, classes.secondPlan, classes.highlightedPlan],
        [classes.plan, classes.thirdPlan]
      ][idx];

      return {
        ...price,
        classes: planClasses
      };
    })
    .filter((price) => {
      return price.id !== currentSubscriptionPrice.id && price.price > currentSubscriptionPrice.price;
    });

  return (
    <Fragment>
      {loading ? (
        <Spinner color="secondary" />
      ) : (
        <Fragment>
          <Box className={classes.subscription} mb={2} borderRadius="borderRadius">
            <Typography className={classes.subscriptionName} gutterBottom>
              Monthly Subscription: {subscription.name}
            </Typography>
            <div className={classes.flex}>
              <div className={classes.circle}>{subscription.reports_remaining}</div>
              <div>
                <Typography>{`Reports remaining until ${moment.unix(subscription.ends).format("MM/DD/YY h:mm A")}.`}</Typography>
                <Typography>{`${subscription.reports_limit - subscription.reports_remaining} / ${
                  subscription.reports_limit
                } Reports run this period.`}</Typography>
              </div>
            </div>
          </Box>

          {prices.length > 0 ? (
            <Fragment>
              <Typography variant="h6">You have options!</Typography>
              <Typography gutterBottom>
                You can upgrade your current subscription, or purchase a single report. Select your option below:
              </Typography>

              {!!deviceScreen.isMobile ? (
                prices.map((price, idx) => (
                  <Accordion
                    key={price.id}
                    expanded={expanded === idx}
                    onChange={handlePanels(idx)}
                    className={`${classes.panel} ${price.classes.join(" ")}`}
                    classes={{ root: classes.accordionRoot }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      classes={{ content: classes.headerPriceContainer }}
                    >
                      <Typography className={classes.planHeadingMobile}>{price.name}</Typography>
                      <Typography className={classes.planePriceMobile}>
                        ${(price.price / 100).toFixed(2)} <small>/month</small>
                        <small className={classes.planBestDeal}>(Best deal)</small>
                      </Typography>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      {price.description && (
                        <div className={classes.planDescriptionRow}>
                          <CheckCircleIcon fontSize="small" />
                          <Typography variant="body2" align="left">
                            {price.description}
                          </Typography>
                        </div>
                      )}
                      <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.planButton}
                        onClick={handleSelectPrice(price)}
                      >
                        Select
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <Box my={2} className={classes.plans}>
                  {prices.map((price, idx) => (
                    <Box key={price.id} p={1} borderRadius="borderRadius" className={price.classes.join(" ")} textAlign="center">
                      <Typography className={classes.planHeading}>{price.name}</Typography>
                      <Typography className={classes.planPrice} gutterBottom>
                        ${(price.price / 100).toFixed(2)} <small>/month</small>
                        <small className={classes.planBestDeal}>(Best deal)</small>
                      </Typography>

                      <Divider />

                      {price.description && (
                        <div className={classes.planDescriptionRow}>
                          <CheckCircleIcon fontSize="small" />
                          <Typography align="left">{price.description}</Typography>
                        </div>
                      )}

                      <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.planButton}
                        onClick={handleSelectPrice(price)}
                        disabled={checkoutLoading}
                      >
                        Select
                      </Button>
                    </Box>
                  ))}
                </Box>
              )}
            </Fragment>
          ) : (
            <Typography gutterBottom>You can only purchase a single report.</Typography>
          )}

          {onetimePrices.map((price) => (
            <Box key={price.id} className={classes.oneTime} mt={4} borderRadius="borderRadius">
              <Typography className={classes.oneTimeHeading}>One Time Report</Typography>
              <Typography className={classes.oneTimePrice}>{`$${(price.price / 100).toFixed(2)}`}</Typography>

              <div className={classes.planDescriptionRow}>
                <DoneIcon fontSize="small" style={{ marginRight: 4 }} />
                <Typography className={classes.oneTimeDescription} variant="body2" align="left">
                  Access Anytime
                </Typography>
              </div>

              <Button
                variant="outlined"
                color="secondary"
                className={classes.oneTimeButton}
                onClick={handleSelectPrice(price)}
                disabled={checkoutLoading}
              >
                Select
              </Button>
            </Box>
          ))}
        </Fragment>
      )}
    </Fragment>
  );
};

export default withStyles(styles)(Component);
