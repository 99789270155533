import React, { Fragment } from "react";
import MuiSwitch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { Controller } from "react-hook-form";
import Typography from "./Typography";

const styles = (theme) => ({
  labelSwitchContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1.2)
  },
  label: {
    fontSize: 18,
    fontWeight: 400,
    cursor: "pointer"
  },
  labelBold: {
    fontWeight: 600,
    cursor: "pointer"
  },
  subText: {
    fontSize: 12,
    opacity: 0.6
  }
});

function Switch({
  classes,
  name,
  label,
  labelBold = false,
  control,
  checked = false,
  handleChange,
  id,
  subText,
  notificationID
}) {
  const formatLabelStyle = () => {
    let cNames = `${classes.label}`;
    if (labelBold) cNames = cNames + ` ${classes.labelBold}`;
    return cNames;
  };

  const handleSwitch = () => {
    if (notificationID) {
      handleChange(checked, id, notificationID);
      return;
    }
    handleChange(checked, id);
  };

  return (
    <div className={classes.labelSwitchContainer}>
      <div onClick={handleSwitch}>
        <Typography className={formatLabelStyle()}>{label}</Typography>
        {subText && (
          <Typography variant="description" className={classes.subText}>
            {subText}
          </Typography>
        )}
      </div>
      <Controller name={name} control={control} render={() => <MuiSwitch checked={checked} onChange={handleSwitch} />} />
    </div>
  );
}

export default withStyles(styles)(Switch);
