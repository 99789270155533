import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({});

const Component = ({ classes, matches, maxMatches }) => (
  <Fragment>
    <Box>
      <div>52 matches</div>
    </Box>

    <Typography variant="h6">Ok... So what now?</Typography>

    <Typography>
      In order to guarantee <em>the best</em> matches, we limit the max number of matches per a report to {maxMatches}.
    </Typography>
    <Typography>
      If you run the report anyway, you will get {maxMatches} of the {matches} and we can't guarantee any quality in the matches.
    </Typography>
  </Fragment>
);

export default withStyles(styles)(Component);
