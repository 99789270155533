import { createMuiTheme } from "@material-ui/core/styles";
import { green, grey, red, amber, orange } from "@material-ui/core/colors";

const rawTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#fff",
      main: "#D0112B",
      dark: "#D0112B"
    },
    secondary: {
      xLight: "#0A1971",
      light: "#0A1971",
      main: "#0A1971",
      dark: "#0A1971"
    },
    warning: {
      main: "#ffc071",
      dark: "#ffb25e"
    },
    error: {
      xLight: "#F28C11",
      light: "#F28C11",
      // main: '#C28944',
      main: "#F28C11",
      dark: "#C28944"
    },
    success: {
      xLight: "#009577",
      main: "#009577",
      dark: "#009577"
    },
    border: {
      main: "#949494",
      light: "#B7B7B7"
    },
    gray: {
      main: "#F1F1F1",
      dark: "#999"
    },
    purple: {
      main: "#10092b"
    },
    neonBlue: {
      main: "#5529ff"
    }
  },
  typography: {
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131"
        }
      }
    }
  }
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamilySecondary,
  textTransform: "uppercase"
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[200]
    }
  },
  helpers: {
    flex: {
      display: "flex",
      alignItems: "center"
    }
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 60
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 54,
      fontWeight: rawTheme.typography.fontWeightRegular,
      textTransform: "none"
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 42
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: 28,
      textTransform: "none",
      fontWeight: rawTheme.typography.fontWeightRegular
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 20,
      fontWeight: rawTheme.typography.fontWeightLight
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 18,
      textTransform: "none"
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18,
      fontWeight: rawTheme.typography.fontWeightMedium
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 16
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 14
    }
  }
};

export default theme;
