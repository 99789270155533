import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import SubscriptionsOutlinedIcon from "@material-ui/icons/SubscriptionsOutlined";
import ContactsOutlinedIcon from "@material-ui/icons/ContactsOutlined";
import PrefsOutlinedIcon from "@material-ui/icons/TuneOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import ListAltIcon from "@material-ui/icons/ListAlt";

import Container from "./Container";
import { useAuth, USER_TYPES } from "utils/auth";
import { RESOURCES } from "utils/api";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.light,
    padding: `0px ${theme.spacing(3)}`
  },
  hideOnTablet: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  title: {
    flexGrow: 1,
    fontWeight: "normal",

    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  signin: {
    whiteSpace: "nowrap",
    color: theme.palette.primary.light
  },
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none"
  },
  logo: {
    width: 190,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(2)
    }
  },
  space: {
    flexGrow: 1
  },
  button: {
    color: theme.palette.primary.light,
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 16,
    marginLeft: theme.spacing(1),
    borderRadius: 0,
    lineHeight: 1,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14
    },

    "&.active": {
      "&::before": {
        content: "' '",
        height: 5,
        width: "100%",
        backgroundColor: "#43b2e4",
        position: "absolute",
        top: 41,
        [theme.breakpoints.down("xs")]: {
          top: 37
        }
      }
    }
  },
  menuRoot: {
    width: 400,
    fontSize: 18
  },
  menuIconSpacer: {
    marginRight: theme.spacing(1.5)
  },
  accountButtonLabel: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  startIcon: {
    [theme.breakpoints.down("xs")]: {
      margin: 0
    }
  }
});

function AppBar({ classes }) {
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [adminAnchorEl, setAdminAnchorEl] = React.useState(null);
  const [tabletAnchorEl, setTabletAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAdminMenu = (event) => {
    setAdminAnchorEl(event.currentTarget);
  };

  const handleTabletMenu = (event) => {
    setTabletAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAdminAnchorEl(null);
    setTabletAnchorEl(null);
  };

  return (
    <MuiAppBar elevation={0} position="fixed" className={classes.root}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Link to="/" className={classes.link}>
            <img src="/assets/rpx-white-logo.png" alt="logo" className={classes.logo} />
          </Link>

          <div className={classes.space} />

          {auth.loading ? (
            <CircularProgress color="secondary" size={24} />
          ) : auth.user ? (
            <Fragment>
              <div className={classes.hideOnTablet}>
                {auth.userType[USER_TYPES.Retailer] && (
                  <Button component={NavLink} to="/retailers" exact className={classes.button}>
                    My&nbsp;Tenant&nbsp;Requirements
                  </Button>
                )}

                {auth.userType[USER_TYPES.Broker] && (
                  <Button component={NavLink} to="/properties" exact className={classes.button}>
                    My&nbsp;Properties
                  </Button>
                )}

                {auth.user.user_types.some((type) => type === USER_TYPES.Broker || type === USER_TYPES.Retailer) && (
                  <Button component={NavLink} to="/reports" exact className={classes.button}>
                    My&nbsp;Reports
                  </Button>
                )}
              </div>

              <div className={classes.hideOnDesktop}>
                <Button
                  aria-label="actions"
                  aria-controls="tablet-menu-appbar"
                  aria-haspopup="true"
                  onClick={handleTabletMenu}
                  classes={{ startIcon: classes.startIcon }}
                  startIcon={<ListAltIcon />}
                  className={classes.button}
                >
                  <ExpandMoreOutlinedIcon />
                </Button>

                <Menu
                  className={classes.menuRoot}
                  id="tablet-menu-appbar"
                  anchorEl={tabletAnchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  open={Boolean(tabletAnchorEl)}
                  onClose={handleClose}
                >
                  {auth.userType[USER_TYPES.Retailer] && (
                    <MenuItem key={1} component={Link} to="/retailers">
                      My Tenant Requirements
                    </MenuItem>
                  )}

                  {auth.userType[USER_TYPES.Broker] && (
                    <MenuItem key={2} component={Link} to="/properties">
                      My Properties
                    </MenuItem>
                  )}

                  {auth.user.user_types.some((type) => type === USER_TYPES.Broker || type === USER_TYPES.Retailer) && (
                    <MenuItem key={3} component={Link} to="/reports">
                      My Reports
                    </MenuItem>
                  )}
                </Menu>
              </div>

              {auth.userType[USER_TYPES.Admin] && (
                <Fragment>
                  <Button
                    aria-label="actions"
                    aria-controls="admin-menu-appbar"
                    aria-haspopup="true"
                    onClick={handleAdminMenu}
                    classes={{ startIcon: classes.startIcon }}
                    startIcon={<SettingsIcon />}
                    className={classes.button}
                  >
                    <span className={classes.accountButtonLabel}>Admin</span>
                    <ExpandMoreOutlinedIcon />
                  </Button>

                  <Menu
                    className={classes.menuRoot}
                    id="admin-menu-appbar"
                    anchorEl={adminAnchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right"
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    open={Boolean(adminAnchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem key={1} component={Link} to="/admin/retailers">
                      Retailers
                    </MenuItem>
                    <MenuItem key={2} component={Link} to="/admin/users">
                      Users
                    </MenuItem>
                    <MenuItem key={3} component={Link} to="/admin/properties">
                      Properties
                    </MenuItem>
                    <MenuItem key={4} component={Link} to="/admin/inviteCodes">
                      Invite Codes
                    </MenuItem>
                  </Menu>
                </Fragment>
              )}

              <Button
                aria-label="actions"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                classes={{ startIcon: classes.startIcon }}
                startIcon={<AccountCircleOutlinedIcon />}
                className={classes.button}
              >
                <span className={classes.accountButtonLabel}>{auth.user.first_name}</span>
                <ExpandMoreOutlinedIcon />
              </Button>

              <Menu
                className={classes.menuRoot}
                id="menu-appbar"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem component={Link} to="/account/profile">
                  <AccountCircleOutlinedIcon className={classes.menuIconSpacer} />
                  Profile
                </MenuItem>

                <MenuItem component={Link} to="/account">
                  <LockOutlinedIcon className={classes.menuIconSpacer} />
                  Account
                </MenuItem>

                <MenuItem component={Link} to="/account/contacts">
                  <ContactsOutlinedIcon className={classes.menuIconSpacer} />
                  Contacts
                </MenuItem>

                {!auth.userType[USER_TYPES.Admin] && (
                  <MenuItem component={Link} to="/account/preferences">
                    <PrefsOutlinedIcon className={classes.menuIconSpacer} />
                    Preferences
                  </MenuItem>
                )}

                <MenuItem disabled component={Link} to="/account/subscription">
                  <SubscriptionsOutlinedIcon className={classes.menuIconSpacer} />
                  Subscription
                </MenuItem>

                <Divider />

                <MenuItem style={{ paddingLeft: 52 }} onClick={auth.logout}>
                  Sign Out
                </MenuItem>
              </Menu>
            </Fragment>
          ) : (
            <Button component={Link} to="/login" className={classes.signin}>
              Sign In
            </Button>
          )}
        </Toolbar>
      </Container>
    </MuiAppBar>
  );
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AppBar);
