import React, { Fragment } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";

import { useAPI, RESOURCES } from "utils/api";
import { useGlobalUI } from "context/globalUI";
import { useAuth } from "utils/auth";
import Modal from "components/Modal";
import Spinner from "components/Spinner";
import HighMatchesNumberState from "./children/HighMatchesNumberState";
import NoMatchesState from "./children/NoMatchesState";
import SubscriptionHasReportsState from "./children/SubscriptionHasReportsState";
import SubscriptionOutOfReportsState from "./children/SubscriptionOutOfReportsState";
import NoSubscriptionState from "./children/NoSubscriptionState";
import PaymentState from "./children/PaymentState";
import ReportNameState from "./children/ReportNameState";

const STATES = {
  Init: 0,
  SaveOrRun: 1,
  HighMatchesNumber: 2,
  NoMatches: 3,
  SubscriptionHasReports: 4,
  SubscriptionOutOfReports: 5,
  NoSubscription: 6,
  Payment: 7,
  ReportName: 8
};

const styles = (theme) => ({
  root: {
    padding: 0
  },
  buttons: {
    ...theme.helpers.flex,
    "& > *": {
      marginLeft: theme.spacing(1)
    }
  }
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export const Modes = {
  DEFAULT: 0,
  ASK_CONIRMATION: 1,
  BUY_SUBSCRIPTION: 2
};

const RunReportModal = ({ classes, property, retailer, onCancel, onSuccess, mode = Modes.DEFAULT }) => {
  const [loading, setLoading] = React.useState(true);
  const [currentState, setCurrentState] = React.useState(STATES.Init);
  const [paymentData, setPaymentData] = React.useState(null);
  const [reportName, setReportName] = React.useState(
    `${property ? property.name : retailer.name} - ${moment().format("MM/DD/YYYY")}`
  );

  const [matches, setMatches] = React.useState(0);
  const [maxMatches, setMaxMatches] = React.useState(0);
  const [subscription, setSubscription] = React.useState(null);
  const [recurringPrices, setRecurringPrices] = React.useState([]);
  const [onetimePrices, setOnetimePrices] = React.useState([]);

  const api = useAPI();
  const location = useLocation();
  const { user } = useAuth();
  const { snackbar } = useGlobalUI();

  async function loadPrices() {
    const prices = await api.callWithAuth("GET", RESOURCES.accountSubscriptionPrices);

    const onetimePrices = [];
    const recurringPrices = [];

    prices.forEach((price) => {
      if (price.type === "recurring") {
        recurringPrices.push(price);
      } else {
        onetimePrices.push(price);
      }
    });

    if (mode !== Modes.BUY_SUBSCRIPTION) {
      // Do not show one-time options when the modal is opened from Manage Subscription page
      setOnetimePrices(onetimePrices);
    }

    setRecurringPrices(recurringPrices);
  }

  async function load() {
    if (mode === Modes.BUY_SUBSCRIPTION) {
      await loadPrices();
      setCurrentState(STATES.NoSubscription);
      setLoading(false);
      return;
    }

    let subscription;
    let matchesResult;

    try {
      const matchesURL = property
        ? `${RESOURCES.properties}/${property.id}/matches`
        : `${RESOURCES.retailers}/${retailer.id}/matches`;

      [subscription, matchesResult] = await Promise.all([
        api.callWithAuth("GET", RESOURCES.accountSubscription),
        api.callWithAuth("POST", matchesURL)
      ]);
    } catch (e) {
      snackbar.open({ message: e.message, color: "error" });
      return;
    }

    setSubscription(subscription);
    setMatches(matchesResult.matches);
    setMaxMatches(matchesResult.maxMatches);

    if (!matchesResult.matches) {
      setCurrentState(STATES.NoMatches);
    } else if (!subscription) {
      await loadPrices();
      setCurrentState(STATES.NoSubscription);
    } else if (!subscription.reports_remaining) {
      await loadPrices();
      setCurrentState(STATES.SubscriptionOutOfReports);
    } else {
      setCurrentState(STATES.SubscriptionHasReports);
    }

    setLoading(false);
  }

  React.useEffect(() => {
    if (mode === Modes.ASK_CONIRMATION) {
      setCurrentState(STATES.SaveOrRun);
      setLoading(false);
      return;
    }

    load();
  }, []);

  const handleSaveOrRunContinue = () => {
    setLoading(true);
    setCurrentState(STATES.Init);

    load();
  };

  const handleSelectPrice = async (price) => {
    const data = {
      price_id: price.id,
      price_type: price.type
    };

    if (property) {
      data.property_id = property.id;
    }

    if (retailer) {
      data.retailer_id = retailer.id;
    }

    // TODO: Remove when moving to Phase 2
    if (price.type === "recurring") {
      return;
    }

    // TODO: Remove when moving to Phase 2
    if (price.price === 0) {
      setCurrentState(STATES.ReportName);
      return;
    }

    setPaymentData(data);
    setCurrentState(STATES.Payment);
  };

  const handlePaymentSuccess = () => {
    onSuccess();
  };

  const handleNext = () => {
    setCurrentState(STATES.ReportName);
  };

  const handleRun = async () => {
    let report;

    setLoading(true);

    try {
      const reportURL = property
        ? `${RESOURCES.properties}/${property.id}/reports`
        : `${RESOURCES.retailers}/${retailer.id}/reports`;

      report = await api.callWithAuth("POST", reportURL, { data: { name: reportName } });
    } catch (e) {
      snackbar.open({ message: e.message, color: "error" });
      setLoading(false);
      return;
    }

    onSuccess(report);
  };

  const renderModalActions = () => {
    if (currentState === STATES.SaveOrRun) {
      return (
        <div className={classes.buttons}>
          <Button variant="text" onClick={onCancel}>
            No
          </Button>

          <Button variant="contained" color="secondary" onClick={handleSaveOrRunContinue} disabled={loading}>
            Yes
          </Button>
        </div>
      );
    }

    if (currentState === STATES.ReportName) {
      return (
        <div className={classes.buttons}>
          {loading && <Spinner size={20} />}

          <Button variant="text" onClick={onCancel} disabled={loading}>
            Cancel
          </Button>

          <Button variant="contained" color="secondary" onClick={handleRun} disabled={loading || !reportName}>
            Confirm &amp; Run
          </Button>
        </div>
      );
    }

    if (currentState === STATES.Payment) {
      // Buttons are defined in the PaymentState component
      return null;
    }

    return (
      <div className={classes.buttons}>
        <Button variant="text" onClick={onCancel} disabled={loading}>
          Cancel
        </Button>

        {currentState === STATES.SubscriptionHasReports && (
          <Button variant="contained" color="secondary" onClick={handleNext} disabled={loading}>
            Next
          </Button>
        )}
      </div>
    );
  };

  const currentStateComponent = {
    [STATES.Init]: <Spinner color="secondary" />,
    [STATES.SaveOrRun]: <Typography>Your changes has been successfully saved. Do you want to run a new report?</Typography>,
    [STATES.HighMatchesNumber]: <HighMatchesNumberState matches={matches} maxMatches={maxMatches} />,
    [STATES.NoMatches]: <NoMatchesState retailer={!!retailer} />,
    [STATES.SubscriptionHasReports]: <SubscriptionHasReportsState subscription={subscription} />,
    [STATES.SubscriptionOutOfReports]: (
      <SubscriptionOutOfReportsState
        loading={loading}
        subscription={subscription}
        recurringPrices={recurringPrices}
        onetimePrices={onetimePrices}
        onSelectPrice={handleSelectPrice}
      />
    ),
    [STATES.NoSubscription]: (
      <NoSubscriptionState
        loading={loading}
        matches={matches}
        showMatches={mode !== Modes.BUY_SUBSCRIPTION}
        recurringPrices={recurringPrices}
        onetimePrices={onetimePrices}
        onSelectPrice={handleSelectPrice}
        retailer={!!retailer}
      />
    ),
    [STATES.Payment]: (
      <Elements stripe={stripePromise}>
        <PaymentState
          paymentData={paymentData}
          onCancel={onCancel}
          onSuccess={handlePaymentSuccess}
          redirectUrl={mode === Modes.BUY_SUBSCRIPTION ? "" : property ? "/properties/reports" : "/retailers/reports"}
          loading={loading}
        />
      </Elements>
    ),
    [STATES.ReportName]: <ReportNameState name={reportName} onNameChange={setReportName} />
  }[currentState];

  const title =
    mode === Modes.BUY_SUBSCRIPTION ? "Choose a plan" : `Run Report for "${property ? property.name : retailer.name}"`;

  return (
    <Modal open={true} title={title} actions={renderModalActions()}>
      {currentStateComponent}
    </Modal>
  );
};

export default withStyles(styles)(RunReportModal);
