import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { useAuth } from "utils/auth";
import Modal from "components/Modal";
import Spinner from "components/Spinner";

const styles = (theme) => ({
  textField: {
    marginTop: theme.spacing(2)
  }
});

const ConfirmNewEmailModal = ({ classes, onCancel, code, onSuccess }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [password, setPassword] = React.useState("");

  const auth = useAuth();

  const handlePasswordChange = ({ target }) => setPassword(target.value);

  const handleConfirm = async () => {
    setError(null);
    setLoading(true);

    try {
      await auth.confirmNewEmail(code, password);
    } catch (e) {
      setError(e.message);
      return;
    } finally {
      setLoading(false);
    }

    onSuccess();
  };

  return (
    <Modal
      open={true}
      title="Confirm Your New Email Address"
      actions={
        <>
          {loading && <Spinner size={20} />}

          <Button variant="text" onClick={onCancel} disabled={loading}>
            Cancel
          </Button>

          <Button variant="text" color="primary" disabled={loading || !password} onClick={handleConfirm}>
            Confirm
          </Button>
        </>
      }
    >
      <Typography paragraph>Please type in your password in order to confirm email address change:</Typography>

      <TextField
        autoFocus
        variant="outlined"
        label="Password"
        className={classes.textField}
        fullWidth
        type="password"
        margin="normal"
        disabled={loading}
        value={password}
        onChange={handlePasswordChange}
        required
      />

      {error && <Typography color="error">{error}</Typography>}
    </Modal>
  );
};

export default withStyles(styles)(ConfirmNewEmailModal);
