import React, { useState, useEffect, useContext, createContext } from "react";
import debounce from "lodash.debounce";

const userFeedbackContext = createContext();

export function UserFeedbackProvider({ children }) {
  const feedback = useProvideUserFeedback();
  return <userFeedbackContext.Provider value={feedback}>{children}</userFeedbackContext.Provider>;
}

export const useUserFeedback = () => {
  return useContext(userFeedbackContext);
};

function useProvideUserFeedback() {
  const [feedback, setFeedback] = useState({
    modalIsOpen: false,
    pageCallback: () => null,
    message: ""
  });

  function openFeedbackModal({ pageCallback = () => null, message = "" }) {
    setFeedback({ modalIsOpen: true, pageCallback, message });
  }
  function closeFeedbackModal() {
    setFeedback({ modalOpen: false });
  }

  return {
    userFeedback: {
      openModal: openFeedbackModal,
      closeModal: closeFeedbackModal,
      ...feedback
    }
  };
}
