import React, { Fragment } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  select: {
    fontSize: 12,
    borderBottom: "none",
    paddingRight: theme.spacing(0.5)
  },
  formControl: {
    margin: theme.spacing(-0.5, 1, 0)
  },
  nowrap: {
    display: "inline-block",
    whiteSpace: "nowrap"
  }
});

function Pagination({ classes, disabled, limit, start, onChangeLimit, onChangeStart, totalRows, hideRowsPerPage }) {
  function handleChange({ target }) {
    onChangeLimit(target.value);
  }

  const handleChangeStart = (num) => () => {
    onChangeStart(Math.max(start + num, 0));
  };

  return (
    <div className={classes.root}>
      {!hideRowsPerPage && (
        <div className={classes.nowrap}>
          <Typography variant="caption">Rows per page:</Typography>
          <FormControl className={classes.formControl}>
            <Select value={limit} onChange={handleChange} disabled={disabled} disableUnderline classes={{ root: classes.select }}>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}

      <div className={classes.nowrap}>
        <IconButton size="small" disabled={disabled || start < 1} onClick={handleChangeStart(-limit)}>
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="caption">{`${start + 1}-${Math.min(start + limit, totalRows)} of ${totalRows}`}</Typography>
        <IconButton size="small" disabled={disabled || start + limit >= totalRows} onClick={handleChangeStart(limit)}>
          <ChevronRightIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default withStyles(styles)(Pagination);
