import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    backgroundColor: "#fff",
    color: "#555",
    cursor: "pointer",
    border: "1px solid #CCC",
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(1.5, 2),
    textDecoration: "none",
    position: "fixed",
    right: 0,
    bottom: 0,
    zIndex: 10,
    display: "block",
    boxShadow: "0px 0px 5px 0px #AAA"
  }
});

const ProvideFeedbackBar = ({ classes }) => {
  return (
    <a
      className={classes.root}
      href="https://docs.google.com/forms/d/e/1FAIpQLSdoR8CaKWRo2_j5hEOx4mSniKGRw8UWJ9E8H1SKRdUSm7dzMw/viewform"
      target="_blank"
    >
      Provide Feedback
    </a>
  );
};

export default withStyles(styles)(ProvideFeedbackBar);
