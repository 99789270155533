import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import Container from "components/Container";
import AppBar from "components/AppBar";

const styles = (theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    marginTop: "-10%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-100px"
    }
  },
  gridContainer: {
    // width: "70%"
  },
  titleText: {
    fontSize: 40,
    fontWeight: 300,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  imgContainer: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  image: {
    [theme.breakpoints.between(600, 800)]: {
      maxWidth: "100%"
    }
  },
  contentText: {
    fontSize: 26,
    fontWeight: 300,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  ctaButton: {
    fontSize: 18,
    fontWeight: 400,
    width: 255,
    height: 56,
    marginTop: theme.spacing(6),
    textTransform: "capitalize"
  }
});

function PageNotFound({ classes }) {
  return (
    <>
      <AppBar />
      <Container className={classes.root}>
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item md={3} sm={2} xs={0}></Grid>
          <Grid item md={9} sm={10} xs={12} className={classes.titleText}>
            Interesting...
            <br />
            We can't find what you're looking for.
          </Grid>
          <Grid item md={4} sm={4} xs={12} align="right" className={classes.imgContainer}>
            <img src="/assets/alien-abduction.svg" className={classes.image} />
          </Grid>
          <Grid item md={8} sm={8} xs={12} className={classes.contentText}>
            Something may have been here before, but it appears to be gone for some reason.... Very Peculiar...
            <br />
            <Button color="primary" variant="contained" component={Link} to="/" className={classes.ctaButton}>
              RealPropX Home
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default withStyles(styles)(PageNotFound);
