import axios from "axios";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Modal from "components/Modal";

const VERSION_HEADER_NAME = "x-api-version";

const frontendVersionWeight = calculateVersionWeight(process.env.REACT_APP_VERSION);

function refresh() {
  setTimeout(function () {
    window.location.reload(true);
  }, 100);
}

function calculateVersionWeight(version) {
  const versionArray = version.split(".");
  return +versionArray[0] * 10000 + +versionArray[1] * 100 + +versionArray[2];
}

const ReloadModal = () => (
  <Modal
    open
    title="New Version Available"
    actions={
      <Button color="primary" variant="text" onClick={refresh}>
        Refresh
      </Button>
    }
  >
    <Typography>You’re using an outdated version of the RealPropX. Please click Refresh to load the newest version</Typography>
  </Modal>
);

function VersionCheck() {
  const [showReloadModal, setShowReloadModal] = React.useState(false);

  React.useEffect(() => {
    axios.interceptors.response.use((response) => {
      const version = response.headers[VERSION_HEADER_NAME];
      if (typeof version === "string") {
        const apiVersionWeight = calculateVersionWeight(version);

        if (apiVersionWeight > frontendVersionWeight) {
          setShowReloadModal(true);
        }
      }

      return response;
    });
  }, []);

  if (!showReloadModal) {
    return null;
  }

  return <ReloadModal />;
}

export default VersionCheck;
