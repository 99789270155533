import React, { Fragment } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default ({ fullScreen, ...restProps }) => {
  let style = {
    textAlign: "center"
  };

  if (fullScreen) {
    style = {
      position: "fixed",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    };
  }

  return (
    <div style={style}>
      <CircularProgress color="primary" {...restProps} />
    </div>
  );
};
