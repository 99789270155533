import React, { Fragment } from "react";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";

import { useAPI, RESOURCES } from "utils/api";
import { useAuth, USER_TYPES } from "utils/auth";
import { useGlobalUI } from "context/globalUI";
import history from "utils/history";
import Spinner from "components/Spinner";
import Pagination from "components/Pagination";
import Sort from "components/Sort";
import AppBar from "components/AppBar";
import Container from "components/Container";
import Typography from "components/Typography";
import Card from "components/Card";
import NoData from "components/NoData";
import PageTitle from "components/PageTitle";

const styles = (theme) => ({
  container: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  },
  table: {
    margin: theme.spacing(2, 0)
  },
  paginationContainer: {
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left"
    }
  },
  tableHeadCell: {
    borderBottom: `3px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    fontSize: 18
  },
  reportName: {
    fontWeight: theme.typography.fontWeightMedium
  },
  selectFormControl: {
    minWidth: 200,
    margin: theme.spacing(0, 1, 1, 0),

    [theme.breakpoints.down("xs")]: {
      minWidth: "100%"
    }
  },
  actions: {
    ...theme.helpers.flex,
    justifyContent: "flex-end",
    flexWrap: "wrap",

    "& > *": {
      margin: theme.spacing(0, 0, 1, 1)
    }
  },
  flex: {
    ...theme.helpers.flex,
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start"
    }
  }
});

const SORT_OPTIONS = {
  default: "By Last Run (default)",
  name: "By Report Name ↑",
  "-name": "By Report Name ↓"
};

function Reports({ classes }) {
  const [loading, setLoading] = React.useState(true);
  const [properties, setProperties] = React.useState([]);
  const [retailers, setRetailers] = React.useState([]);
  const [data, setData] = React.useState(null);
  const [meta, setMeta] = React.useState(null);
  const [limit, setLimit] = React.useState(20);
  const [start, setStart] = React.useState(0);
  const [selectedEntityId, setSelectedEntityId] = React.useState(0);
  const [selectedRetailerId, setSelectedRetailerId] = React.useState(0);
  const [selectedSort, setSelectedSort] = React.useState("default");

  const api = useAPI();
  const auth = useAuth();
  const { snackbar } = useGlobalUI();
  const params = useParams();

  async function fetchReports() {
    setLoading(true);

    const queryParams = { start, limit, sort: selectedSort };

    if (params.propertyId) {
      queryParams.property_id = +params.propertyId;
    }

    if (params.retailerId) {
      queryParams.retailer_id = +params.retailerId;
    }

    try {
      const { data, meta } = await api.callWithAuth("GET", RESOURCES.reports, { params: queryParams });

      setData(data);
      setMeta(meta);
    } catch (e) {
      snackbar.open({ message: e.message, color: "error" });
      return;
    }

    setLoading(false);
  }

  async function fetchPropertiesWithReports() {
    try {
      const properties = await api.callWithAuth("GET", RESOURCES.propertiesWithReports);

      setProperties(properties);
    } catch (e) {
      snackbar.open({ message: e.message, color: "error" });
      return;
    }
  }

  const handleSelectedEntityIdChange = ({ target }) => {
    setSelectedEntityId(target.value);

    if (target.value) {
      const [type, id] = target.value.split(":");
      if (type === "p") {
        history.push(`/properties/${id}/reports`);
      } else if (type === "r") {
        history.push(`/retailers/${id}/reports`);
      }
    } else {
      history.push("/reports");
    }
  };

  async function fetchRetailersWithReports() {
    try {
      const retailers = await api.callWithAuth("GET", RESOURCES.retailersWithReports);

      setRetailers(retailers);
    } catch (e) {
      snackbar.open({ message: e.message, color: "error" });
      return;
    }
  }

  const handleSortChange = (sort) => setSelectedSort(sort);

  React.useEffect(() => {
    if (auth.userType[USER_TYPES.Broker]) {
      fetchPropertiesWithReports();
    }

    if (auth.userType[USER_TYPES.Retailer]) {
      fetchRetailersWithReports();
    }
  }, []);

  React.useEffect(() => {
    if (params.propertyId) {
      setSelectedEntityId(`p:${params.propertyId}`);
    } else if (params.retailerId) {
      setSelectedEntityId(`r:${params.retailerId}`);
    } else {
      setSelectedEntityId(0);
    }
  }, [params]);

  React.useEffect(() => {
    fetchReports();
  }, [start, limit, selectedEntityId, selectedSort]);

  return (
    <Fragment>
      <AppBar />

      <Container className={classes.container}>
        <Card customStyle={{ overflow: "auto" }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} align="left">
              <FormControl variant="outlined" size="small" className={classes.selectFormControl}>
                <InputLabel id="entities-select-label">Showing reports for:</InputLabel>
                <Select
                  labelId="entities-select-label"
                  id="entities-select"
                  value={selectedEntityId}
                  onChange={handleSelectedEntityIdChange}
                  label="Showing reports for:"
                >
                  <MenuItem value={0}>All Reports</MenuItem>
                  {auth.userType[USER_TYPES.Broker] && properties.length
                    ? [
                        <Divider key="d1" />,
                        ...properties.map(({ id, name }) => (
                          <MenuItem key={`p:${id}`} value={`p:${id}`}>
                            {name}
                          </MenuItem>
                        ))
                      ]
                    : null}
                  {auth.userType[USER_TYPES.Retailer] && retailers.length
                    ? [
                        <Divider key="d2" />,
                        ...retailers.map(({ id, name }) => (
                          <MenuItem key={`r:${id}`} value={`r:${id}`}>
                            {name}
                          </MenuItem>
                        ))
                      ]
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} align="right">
              <div className={classes.flex}>
                {meta && (
                  <Pagination
                    disabled={loading}
                    limit={limit}
                    start={start}
                    onChangeLimit={setLimit}
                    onChangeStart={setStart}
                    totalRows={meta.total}
                    hideRowsPerPage
                  />
                )}
                <Sort disabled={loading} options={SORT_OPTIONS} selected={selectedSort} onChange={handleSortChange} />
              </div>
            </Grid>
          </Grid>

          {loading ? (
            <Spinner color="secondary" />
          ) : !!(data.length <= 0) ? (
            <NoData
              text={
                "No Reports to show yet. Create a property or tenant requirements to run a report. You can always find your reports here in the future."
              }
            />
          ) : (
            <Table className={classes.table} aria-label="my reports">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeadCell}>Report Name</TableCell>
                  {auth.userType[USER_TYPES.Broker] && auth.userType[USER_TYPES.Retailer] && (
                    <TableCell className={classes.tableHeadCell}>Type</TableCell>
                  )}
                  <TableCell className={classes.tableHeadCell}>Matches</TableCell>
                  <TableCell className={classes.tableHeadCell}>Date Run</TableCell>
                  <TableCell className={classes.tableHeadCell} align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(({ id, property_id, retailer_id, name, results_count, report_date }) => (
                  <TableRow hover key={id}>
                    <TableCell className={classes.reportName}>{name}</TableCell>
                    {auth.userType[USER_TYPES.Broker] && auth.userType[USER_TYPES.Retailer] && (
                      <TableCell>{property_id ? "Property" : "Requirements"}</TableCell>
                    )}
                    <TableCell>{results_count}</TableCell>
                    <TableCell>{moment.utc(report_date).local().format("MM/DD/YYYY")}</TableCell>
                    <TableCell>
                      <div className={classes.actions}>
                        <Button
                          size="small"
                          variant="outlined"
                          component={Link}
                          to={
                            property_id ? `/properties/${property_id}/reports/${id}` : `/retailers/${retailer_id}/reports/${id}`
                          }
                        >
                          View Report
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} className={classes.paginationContainer}>
              {meta && (
                <Pagination
                  disabled={loading}
                  limit={limit}
                  start={start}
                  onChangeLimit={setLimit}
                  onChangeStart={setStart}
                  totalRows={meta.total}
                />
              )}
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Fragment>
  );
}

export default withStyles(styles)(Reports);
