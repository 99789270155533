import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";

import TextField from "components/TextField";
import AppBar from "components/AppBar";
import Footer from "components/Footer";
import Container from "components/Container";
import Typography from "components/Typography";
import Spinner from "components/Spinner";
import ForgotPasswordModal from "./children/ForgotPasswordModal";
import ResetPasswordModal from "./children/ResetPasswordModal";
import ConfirmNewEmailModal from "./children/ConfirmNewEmailModal";
import { useGlobalUI } from "context/globalUI";
import { useAuth } from "utils/auth";
import { parseQueryString } from "utils/qs";

const styles = (theme) => ({
  container: {
    color: "inherit",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  },
  paper: {
    backgroundColor: "#fff",
    padding: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2)
    }
  },
  textField: {
    marginTop: theme.spacing(1)
  }
});

function Login({ classes }) {
  const { register, errors, handleSubmit, watch } = useForm({ mode: "onBlur" });
  const data = watch();

  const auth = useAuth();
  const location = useLocation();
  const { snackbar } = useGlobalUI();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [resetPasswordData, setResetPasswordData] = React.useState(null);
  const [confirmNewEmailData, setConfirmNewEmailData] = React.useState(null);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = React.useState(false);

  const toggleForgotPasswordModal = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal);
  };

  const closeResetPasswordModal = () => {
    setResetPasswordData(null);
  };

  const closeConfirmNewEmailModal = () => {
    setConfirmNewEmailData(null);
  };

  const handleConfirmNewEmailSuccess = () => {
    snackbar.open({ message: "Success! You can now login using your new email address.", color: "success" });
    closeConfirmNewEmailModal();
  };

  const handleSignIn = async ({ email, password }) => {
    setError(null);
    setLoading(true);

    try {
      await auth.login(email, password);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  async function handleVerifyEmail(code) {
    setLoading(true);

    try {
      await auth.verifyEmail(code);
    } catch (e) {
      setError(e.message);
      return;
    } finally {
      setLoading(false);
    }

    snackbar.open({ message: "Thank You! Your email has been confirmed.", color: "success" });
  }

  React.useEffect(() => {
    const qs = parseQueryString(location.search);

    if (qs.code && qs.email) {
      // Reset password using code and email
      setResetPasswordData({ code: qs.code, email: qs.email });
    }

    if (qs.code && qs.action === "new_email_confirm") {
      // Confirm new email address
      setConfirmNewEmailData({ code: qs.code });
    }

    if (qs.code && qs.action === "verify_email") {
      // Verify email address after registration
      handleVerifyEmail(qs.code);
    }
  }, []);

  return (
    <Fragment>
      <AppBar />

      <Container className={classes.container} maxWidth="sm">
        <Paper elevation={0} className={classes.paper}>
          <form onSubmit={handleSubmit(handleSignIn)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography color="inherit" align="center" variant="h3" marked="center">
                  Sign In
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  inputRef={register({ required: "Email is required" })}
                  error={!!errors.email}
                  helperText={errors.email && errors.email.message}
                  label="Email"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  type="email"
                  color="secondary"
                  empty={!data.email}
                  fullWidth
                  autoFocus={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="password"
                  inputRef={register({ required: "Password is required" })}
                  error={!!errors.password}
                  helperText={errors.password && errors.password.message}
                  label="Password"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  type="password"
                  color="secondary"
                  empty={!data.password}
                  fullWidth
                />
              </Grid>

              {error && (
                <Grid item xs={12}>
                  <Typography color="error">{error}</Typography>
                </Grid>
              )}

              <Grid item xs={12}>
                {loading ? (
                  <Spinner />
                ) : (
                  <Button color="primary" variant="contained" fullWidth size="large" type="submit" disabled={loading}>
                    Sign In
                  </Button>
                )}
              </Grid>

              <Grid item xs={12} align="center">
                <Button onClick={toggleForgotPasswordModal}>Forgot Password?</Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>

      {showForgotPasswordModal && (
        <ForgotPasswordModal
          defaultEmail={data.email}
          onCancel={toggleForgotPasswordModal}
          onSuccess={toggleForgotPasswordModal}
        />
      )}

      {resetPasswordData && (
        <ResetPasswordModal
          email={resetPasswordData.email}
          code={resetPasswordData.code}
          onCancel={closeResetPasswordModal}
          onSuccess={closeResetPasswordModal}
        />
      )}

      {confirmNewEmailData && (
        <ConfirmNewEmailModal
          code={confirmNewEmailData.code}
          onCancel={closeConfirmNewEmailModal}
          onSuccess={handleConfirmNewEmailSuccess}
        />
      )}
    </Fragment>
  );
}

export default withStyles(styles)(Login);
