import React, { Fragment } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import SortIcon from "@material-ui/icons/Sort";
import DoneIcon from "@material-ui/icons/Done";

function Sort({ disabled, options, selected, onChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (key) => () => {
    setAnchorEl(null);

    if (key) {
      onChange(key);
    }
  };

  return (
    <Fragment>
      <IconButton disabled={disabled} aria-controls="sort-menu" aria-haspopup="true" onClick={handleClick}>
        <SortIcon />
      </IconButton>

      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose(null)}
      >
        {Object.keys(options).map((key) => (
          <MenuItem onClick={handleClose(key)} key={key}>
            <ListItemIcon>{key === selected && <DoneIcon />}</ListItemIcon>
            <ListItemText>{options[key]}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}

export default Sort;
