import * as Sentry from "@sentry/browser";

import environment from "utils/environment";

export const sentryInit = () => {
  if (!process.env.REACT_APP_SENTRY_DSN) {
    return;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
    environment
  });
};

export const sentrySetScopeUsername = (username) => {
  if (!process.env.REACT_APP_SENTRY_DSN) {
    return;
  }

  Sentry.configureScope((scope) => {
    scope.setUser({
      username
    });
  });
};
