import React, { Fragment } from "react";
import Modal from "components/Modal";
import Typography from "components/Typography";
import { useAuth } from "utils/auth";
import Button from "@material-ui/core/Button";

const WelcomeModal = () => {
  const { user } = useAuth();
  const [showWelcomeModal, setShowWelcomeModal] = React.useState(false);

  React.useEffect(() => {
    setShowWelcomeModal(user && !!!localStorage.getItem('welcome_shown'));
  }, [user]);

  function closeModal() {
    setShowWelcomeModal(false);
    localStorage.setItem('welcome_shown', true);
  }

  if (!showWelcomeModal) {
    return null;
  }
    
  return (
    <Modal
      open={true}
      title={"Welcome to RealPropX"}
      actions={
        <Button color="primary" variant="text" onClick={closeModal}>
          Sounds good!
        </Button>
      }
    >
    <Fragment>
      <img src="/assets/rpx-logo-550.png" alt="logo" style={{maxWidth: '75vw'}} />
      <hr />
      <br />
    </Fragment>
    <Fragment>
      <Typography paragraph={true}>The first system designed to match retailers and restaurants with properties using BOTH demographics and physical characteristics!</Typography>
      <Typography paragraph={true}>As a start-up, we want your feedback on how to get better, so the first time you enter your information, you will be prompted to tell us what you liked and what was missing.  Please don't be afraid to be honest!</Typography>
      <Typography paragraph={true}>Lastly, as we continue to add users and information daily to our system, you will see more and more matches.  So be on the lookout for emails alerting you to new matches suited to your requirements!</Typography>
      <Typography paragraph={true}>We look forward to helping you! </Typography>
      <Typography paragraph={true}>The RealPropX Team</Typography>
    </Fragment>
    </Modal>
  );
}

export default WelcomeModal;
