import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { useAuth } from "utils/auth";
import Modal from "components/Modal";
import Spinner from "components/Spinner";

const styles = (theme) => ({
  textField: {
    marginTop: theme.spacing(2)
  }
});

const ResetPasswordModal = ({ classes, onCancel, email, code, onSuccess }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");

  const auth = useAuth();

  const handlePasswordChange = ({ target }) => setPassword(target.value);
  const handlePassword2Change = ({ target }) => setPassword2(target.value);

  const handleConfirm = async () => {
    if (!email) {
      return;
    }

    setError(null);
    setLoading(true);

    try {
      await auth.resetPassword(email, code, password);
      await auth.login(email, password);
    } catch (e) {
      setError(e.message);
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={true}
      title="Reset Password"
      actions={
        <>
          {loading && <Spinner size={20} />}

          <Button variant="text" onClick={onCancel} disabled={loading}>
            Cancel
          </Button>

          <Button
            variant="text"
            color="primary"
            disabled={loading || !password || password !== password2}
            onClick={handleConfirm}
          >
            Reset
          </Button>
        </>
      }
    >
      <Typography paragraph>
        Your new password must meet these minimum requirements:
        <ul style={{ marginBottom: 0 }}>
          <li>Minimum of 8 characters</li>
          <li>At least 1 uppercase letter</li>
          <li>At least 1 number</li>
          <li>At least 1 special character (!@#&)</li>
        </ul>
      </Typography>

      <TextField
        autoFocus
        variant="outlined"
        label="New Password"
        className={classes.textField}
        fullWidth
        type="password"
        margin="normal"
        disabled={loading}
        value={password}
        onChange={handlePasswordChange}
        required
      />

      <TextField
        variant="outlined"
        label="Confirm New Password"
        className={classes.textField}
        fullWidth
        type="password"
        margin="normal"
        disabled={loading}
        value={password2}
        onChange={handlePassword2Change}
        required
      />

      {error && <Typography color="error">{error}</Typography>}
    </Modal>
  );
};

export default withStyles(styles)(ResetPasswordModal);
