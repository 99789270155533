import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import MaskedInput from "react-input-mask";
import { useForm, Controller } from "react-hook-form";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import sharedStyles from "../styles";
import { useAPI, RESOURCES } from "utils/api";
import TextField from "components/TextField";
import Typography from "components/Typography";
import ControlledAutoComplete from "components/ControlledAutoComplete";
import DeleteAddressModal from "./DeleteAddressModal";
import EditAddressModal from "./EditAddressModal";
import { USStates } from "utils/constants";

const USStatesArray = USStates.split(",");

const validatePhone = (value) => {
  return !value || /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/.test(value);
};

const VALIDATION_RULES = {
  contact_first_name: { required: true },
  contact_last_name: { required: true },
  company_name: { required: true },
  address: { required: true },
  city: { required: true },
  state: {
    required: true
  },
  zip: {
    required: true,
    minLength: {
      value: 5,
      message: "Must be 5 characters mimumum"
    },
    pattern: {
      value: /^[0-9]{5}(\-[0-9]{4})?$/,
      message: "Invalid zip code"
    }
  },
  email: {
    required: true
  },
  phone: {
    required: true,
    validate: validatePhone
  },
  phone_ext: {
    required: false,
    pattern: {
      value: /^[0-9]*$/,
      message: "Only numbers allowed"
    }
  }
};

const styles = (theme) => ({
  ...sharedStyles(theme),
  category: {
    width: "92%",
    margin: "0 auto",
    marginBottom: theme.spacing(3),
    ["&:last-child"]: {
      marginBottom: theme.spacing(6)
    }
  },
  contactHeader: {
    borderBottom: `3px solid ${theme.palette.secondary.main}`,
    display: "flex",
    alignItems: "center",
    justifyContent: ""
  },
  contactHeaderTitle: {
    marginRight: theme.spacing(2),
    fontSize: 24,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  contactHeaderIcon: {
    flexGrow: 1,
    textAlign: "right",
    whiteSpace: "nowrap"
  },
  btnContainer: {
    marginTop: theme.spacing(3),
    textAlign: "right"
  },
  cancelButton: {
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2)
  },
  actionButton: {
    width: 150,
    float: "right",
    fontSize: 16
  }
});

function ContactCard({ classes, contact, onContactUpdate, onContactDelete }) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [collapsed, setCollapsed] = React.useState(contact.id ? true : false);
  const [showDeleteAddressModal, setShowDeleteAddressModal] = React.useState(false);
  const [showEditAddressModal, setShowEditAddressModal] = React.useState(false);
  const [affectedRecords, setAffectedRecords] = React.useState(null);

  const { register, errors, watch, control, reset, setValue, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: { ...contact, confirm_update: false }
  });
  const data = watch();

  const api = useAPI();

  const onSubmit = async (data, evt) => {
    setError(null);
    setLoading(true);

    const url = contact.id ? `${RESOURCES.accountContacts}/${contact.id}` : RESOURCES.accountContacts;

    const dataToSend = { ...data };
    if (evt.detail && evt.detail.confirm_update) {
      dataToSend.confirm_update = true;
    }

    let updatedContact;

    try {
      updatedContact = await api.callWithAuth(contact.id ? "PUT" : "POST", url, { data: dataToSend });
    } catch (e) {
      if (e.data && e.data.records) {
        setAffectedRecords(e.data.records);
        setShowEditAddressModal(true);
        return;
      }

      setError(e.message);
      return;
    } finally {
      setLoading(false);
    }

    setCollapsed(true);
    onContactUpdate(updatedContact);
  };

  const handleEditConfirm = (evt) => {
    setShowEditAddressModal(false);
    setAffectedRecords(null);

    evt.detail = { confirm_update: true };
    handleSubmit(onSubmit)(evt);
  };

  const handleDelete = () => setShowDeleteAddressModal(true);
  const handleDeleteCancel = () => {
    setShowDeleteAddressModal(false);
    setAffectedRecords(null);
  };

  const handleDeleteConfirm = async () => {
    setError(null);
    setLoading(true);

    try {
      await api.callWithAuth("DELETE", `${RESOURCES.accountContacts}/${contact.id}`, { data });
    } catch (e) {
      if (e.data && e.data.records) {
        setAffectedRecords(e.data.records);
        setShowDeleteAddressModal(true);
        return;
      }

      setError(e.message);
      return;
    } finally {
      setLoading(false);
    }

    setCollapsed(true);
    onContactDelete();
  };

  const handleEdit = () => setCollapsed(false);

  const handleEditCancel = () => {
    if (!contact.id) {
      onContactDelete();
      return;
    }

    reset(contact);
    setCollapsed(true);
    setShowEditAddressModal(false);
    setAffectedRecords(null);
  };

  const addressTitle = !contact.id
    ? "New Address"
    : `${contact.contact_name ? `${contact.contact_name} - ` : ""}${contact.full_address}`;

  return (
    <div className={classes.category}>
      <div className={classes.contactHeader}>
        <Typography variant="h6" color="secondary" className={classes.contactHeaderTitle}>
          {addressTitle}
        </Typography>
        <div className={classes.contactHeaderIcon}>
          <Button color="primary" size="small" startIcon={<EditIcon />} onClick={handleEdit} disabled={!collapsed}>
            Edit
          </Button>
          {!!contact.id && (
            <Button color="primary" size="small" startIcon={<DeleteIcon />} onClick={handleDelete} disabled={loading}>
              Delete
            </Button>
          )}
        </div>
      </div>

      <Collapse in={!collapsed}>
        {/* <Box p={3}> */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="company_name"
              inputRef={register(VALIDATION_RULES.company_name)}
              error={!!errors.company_name}
              label="Company Name"
              variant="outlined"
              margin="normal"
              color="secondary"
              empty={!data.company_name}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="contact_first_name"
              inputRef={register(VALIDATION_RULES.contact_first_name)}
              error={!!errors.contact_first_name}
              label="Contact First Name"
              variant="outlined"
              margin="normal"
              color="secondary"
              empty={!data.contact_first_name}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="contact_last_name"
              inputRef={register(VALIDATION_RULES.contact_last_name)}
              error={!!errors.contact_last_name}
              label="Contact Last Name"
              variant="outlined"
              margin="normal"
              color="secondary"
              empty={!data.contact_last_name}
              required
              leftPadding
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              as={
                <MaskedInput mask="(999) 999-9999">
                  {(inputProps) => (
                    <TextField
                      inputProps={inputProps}
                      error={!!errors.phone}
                      helperText={errors.phone && errors.phone.message}
                      label="Phone"
                      variant="outlined"
                      type="tel"
                      margin="normal"
                      color="secondary"
                      empty={!data.phone}
                      required
                      fullWidth
                    />
                  )}
                </MaskedInput>
              }
              name="phone"
              value={data.phone}
              defaultValue=""
              control={control}
              rules={VALIDATION_RULES.phone}
            />

            <FormControlLabel
              control={
                <Controller
                  name="phone_is_cell"
                  render={(props) => (
                    <Checkbox onChange={(e) => props.onChange(e.target.checked)} checked={props.value} inputRef={props.ref} />
                  )}
                  control={control}
                />
              }
              label="This is a mobile phone"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="phone_ext"
              inputRef={register(VALIDATION_RULES.phone_ext)}
              error={!!errors.phone_ext}
              helperText={errors.phone_ext && errors.phone_ext.message}
              label="Phone Extension"
              variant="outlined"
              margin="normal"
              color="secondary"
              empty={!data.phone_ext}
              fullWidth
              leftPadding
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="email"
              inputRef={register(VALIDATION_RULES.email)}
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
              label="Email"
              variant="outlined"
              type="email"
              margin="normal"
              color="secondary"
              empty={!data.email}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="address"
              inputRef={register(VALIDATION_RULES.address)}
              error={!!errors.address}
              label="Address"
              helperText="Address"
              variant="outlined"
              margin="normal"
              color="secondary"
              empty={!data.address}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="address2"
              inputRef={register}
              label="Address line 2"
              helperText="Suite, apt, unit#"
              variant="outlined"
              margin="normal"
              color="secondary"
              empty={!data.address2}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="city"
              inputRef={register(VALIDATION_RULES.city)}
              error={!!errors.city}
              label="City"
              variant="outlined"
              margin="normal"
              color="secondary"
              empty={!data.city}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledAutoComplete
              options={USStatesArray}
              name="state"
              label="State"
              error={!!errors.state}
              margin="normal"
              color="secondary"
              empty={!data.state}
              required
              control={control}
              rules={VALIDATION_RULES.state}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="zip"
              inputRef={register(VALIDATION_RULES.zip)}
              error={!!errors.zip}
              helperText={errors.zip && errors.zip.message}
              label="Zip"
              variant="outlined"
              margin="normal"
              color="secondary"
              empty={!data.zip}
              required
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          {error && (
            <Grid item xs={12}>
              <Typography color="error">{error}</Typography>
            </Grid>
          )}

          <Grid item xs={12} className={classes.btnContainer}>
            <Button className={classes.cancelButton} onClick={handleEditCancel} disabled={loading}>
              Cancel
            </Button>

            <Button
              fullWidth
              color="primary"
              variant="contained"
              className={classes.actionButton}
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        {/* </Box> */}
      </Collapse>

      {showDeleteAddressModal && (
        <DeleteAddressModal
          loading={loading}
          records={affectedRecords}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}

      {showEditAddressModal && (
        <EditAddressModal loading={loading} records={affectedRecords} onConfirm={handleEditConfirm} onCancel={handleEditCancel} />
      )}
    </div>
  );
}

export default withStyles(styles)(ContactCard);
