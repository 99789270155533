import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DoneIcon from "@material-ui/icons/Done";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";

import Spinner from "components/Spinner";
import { useGlobalUI } from "context/globalUI";

const styles = (theme) => ({
  matches: {
    padding: theme.spacing(2),
    background: theme.palette.secondary.main,
    color: "#FFF",
    ...theme.helpers.flex
  },
  matchesFirstLine: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: "large"
  },
  matchesCircle: {
    height: 72,
    width: 72,
    borderRadius: "50%",
    background: theme.palette.primary.main,
    color: "#FFF",
    textAlign: "center",
    marginRight: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& > span": {
      fontSize: 28,
      fontWeight: theme.typography.fontWeightMedium
    }
  },
  accordionRoot: {
    "&::before": {
      display: "none"
    }
  },
  plans: {
    ...theme.helpers.flex,
    justifyContent: "center",
    position: "relative",
    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      zIndex: 10,
      left: -8,
      top: -24,
      right: -8,
      bottom: -24,
      backdropFilter: "blur(3px)"
    }
  },
  plansMobile: {
    position: "relative",
    "&::before": {
      display: "block",
      content: "''",
      position: "absolute",
      zIndex: 10,
      left: -8,
      top: -8,
      right: -8,
      bottom: -8,
      backdropFilter: "blur(3px)"
    }
  },
  panel: {
    marginBottom: theme.spacing(2),
    borderRadius: 4
  },
  plan: {
    minWidth: "33%",
    border: "1px solid #AAA",
    marginLeft: -1,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      width: "auto"
    }
  },
  firstPlan: {
    borderTop: "6px solid #459F85"
  },
  secondPlan: {
    zIndex: 2,
    borderColor: "#0C1C6C",
    borderTop: "6px solid #0C1C6C",
    [theme.breakpoints.down("xs")]: {
      zIndex: 0
    }
  },
  thirdPlan: {
    borderTop: "6px solid #E59038"
  },
  highlightedPlan: {
    marginTop: theme.spacing(-4),
    marginBottom: theme.spacing(-4),
    "& $planBestDeal": {
      display: "block"
    },
    "& $planButton": {
      marginBottom: theme.spacing(4)
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      marginBottom: theme.spacing(2),
      "& $planButton": {
        marginBottom: 0
      }
    }
  },
  planHeading: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(2)
  },
  planHeadingMobile: {
    fontSize: 22,
    fontWeight: theme.typography.fontWeightMedium
  },
  planPrice: {
    fontSize: 22,
    "& > small": {
      fontSize: "x-small"
    }
  },
  planPriceMobile: {
    fontSize: 20,
    "& > small": {
      fontSize: "x-small"
    }
  },
  headerPriceContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  planBestDeal: {
    color: "#777",
    display: "none",
    height: 16
  },
  planDescriptionRow: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    margin: theme.spacing(2, 1),
    "& > :first-child": {
      marginRight: theme.spacing(1)
    }
  },
  planButton: {
    margin: theme.spacing(1, 0, 2),
    borderColor: theme.palette.border.light,
    textTransform: "none",
    [theme.breakpoints.down("xs")]: {
      margin: 0
    }
  },
  oneTime: {
    ...theme.helpers.flex,
    justifyContent: "space-around",
    border: "1px solid #AAA",
    padding: theme.spacing(1, 0)
  },
  oneTimeButton: {
    borderColor: "#AAA",
    color: "#777",
    textTransform: "none",
    "&:hover": {
      color: theme.palette.secondary.main
    }
  },
  oneTimeHeading: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium
  },
  oneTimePrice: {
    fontSize: 18
  },
  asterisk: {
    color: theme.palette.primary.main,
    margin: "0 5px"
  }
});

const Component = ({ classes, loading, retailer, matches, showMatches, recurringPrices, onetimePrices, onSelectPrice }) => {
  const [expanded, setExpanded] = React.useState(false);

  const { deviceScreen } = useGlobalUI();

  const handlePanels = (idx) => (event, isExpanded) => {
    setExpanded(isExpanded ? idx : false);
  };

  const handleSelectPrice = (price) => () => {
    onSelectPrice(price);
  };

  const pricesSorted = recurringPrices
    .sort((a, b) => a.price - b.price)
    .map((price, idx) => {
      const planClasses = [
        [classes.plan, classes.firstPlan],
        [classes.plan, classes.secondPlan, classes.highlightedPlan],
        [classes.plan, classes.thirdPlan]
      ][idx];

      return {
        ...price,
        classes: planClasses
      };
    });

  return (
    <Fragment>
      {loading ? (
        <Spinner color="secondary" />
      ) : (
        <Fragment>
          {showMatches && (
            <Box className={classes.matches} mb={4} borderRadius="borderRadius">
              <div className={classes.matchesCircle}>
                <span>{matches}</span>
                <small>{`Match${matches === 1 ? "" : "es"}`}</small>
              </div>
              <div>
                <Typography className={classes.matchesFirstLine} gutterBottom>
                  {`Your ${retailer ? "requirements have" : "property has"} up to ${matches} certified match${
                    matches === 1 ? "" : "es"
                  }!`}
                  <span className={classes.asterisk}>*</span>
                </Typography>
                {/* <Typography> */}
                {/*   <em>Review your purchase options below to get them!</em> */}
                {/* </Typography> */}
                {/* <Typography variant="caption"> */}
                {/*   <span className={classes.asterisk}>*</span> Based on the plan selected below. */}
                {/* </Typography> */}
              </div>
            </Box>
          )}

          {!!deviceScreen.isMobile ? (
            <Box my={2} className={classes.plansMobile}>
              {pricesSorted.map((price, idx) => (
                <Accordion
                  key={price.id}
                  expanded={expanded === idx}
                  onChange={handlePanels(idx)}
                  className={`${classes.panel} ${price.classes.join(" ")}`}
                  classes={{ root: classes.accordionRoot }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{ content: classes.headerPriceContainer }}
                  >
                    <Typography className={classes.planHeadingMobile}>{price.name}</Typography>
                    <Typography className={classes.planePriceMobile}>
                      ${(price.price / 100).toFixed(2)} <small>/month</small>
                      <small className={classes.planBestDeal}>(Best deal)</small>
                    </Typography>
                  </AccordionSummary>
                  <Divider />
                  <AccordionDetails style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    {price.description && (
                      <div className={classes.planDescriptionRow}>
                        <CheckCircleIcon fontSize="small" />
                        <Typography variant="body2" align="left">
                          {price.description}
                        </Typography>
                      </div>
                    )}
                    <Button
                      variant="outlined"
                      color="secondary"
                      className={classes.planButton}
                      onClick={handleSelectPrice(price)}
                    >
                      Select
                    </Button>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          ) : (
            <Box my={2} className={classes.plans}>
              {pricesSorted.map((price, idx) => (
                <Box key={price.id} p={1} borderRadius="borderRadius" className={price.classes.join(" ")} textAlign="center">
                  <Typography className={classes.planHeading}>{price.name}</Typography>
                  <Typography className={classes.planPrice}>
                    ${(price.price / 100).toFixed(2)} <small>/month</small>
                    <small className={classes.planBestDeal}>(Best deal)</small>
                  </Typography>

                  <Divider />

                  {price.description && (
                    <div className={classes.planDescriptionRow}>
                      <CheckCircleIcon fontSize="small" />
                      <Typography variant="body2" align="left">
                        {price.description}
                      </Typography>
                    </div>
                  )}

                  <Button variant="outlined" color="secondary" className={classes.planButton} onClick={handleSelectPrice(price)}>
                    Select
                  </Button>
                </Box>
              ))}
            </Box>
          )}

          {onetimePrices.map((price) => (
            <Box key={price.id} className={classes.oneTime} mt={4} borderRadius="borderRadius">
              <Typography className={classes.oneTimeHeading}>One Time Report</Typography>
              <Typography className={classes.oneTimePrice}>{`$${(price.price / 100).toFixed(2)}`}</Typography>

              <div className={classes.planDescriptionRow}>
                <DoneIcon fontSize="small" />
                <Typography variant="body2" align="left">
                  Access Anytime
                </Typography>
              </div>

              <Button variant="outlined" color="secondary" className={classes.oneTimeButton} onClick={handleSelectPrice(price)}>
                Select
              </Button>
            </Box>
          ))}
        </Fragment>
      )}
    </Fragment>
  );
};

export default withStyles(styles)(Component);
