import React, { Fragment } from "react";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  subscription: {
    background: theme.palette.secondary.main,
    color: "#FFF",
    padding: theme.spacing(2, 3),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1)
    }
  },
  subscriptionName: {
    fontWeight: theme.typography.fontWeightMedium
  },
  flex: theme.helpers.flex,
  circle: {
    height: 36,
    width: 36,
    borderRadius: "50%",
    background: "#FFF",
    lineHeight: "36px",
    textAlign: "center",
    color: theme.palette.secondary.main,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(2)
  }
});

const Component = ({ classes, subscription }) => (
  <Fragment>
    <Box className={classes.subscription} p={1} mb={2} borderRadius="borderRadius">
      <Typography className={classes.subscriptionName} gutterBottom>
        Monthly Subscription: {subscription.name}
      </Typography>
      <div className={classes.flex}>
        <div className={classes.circle}>{subscription.reports_remaining}</div>
        <div>
          <Typography>{`Reports remaining until ${moment.unix(subscription.ends).format("MM/DD/YY h:mm A")}.`}</Typography>
          <Typography>{`${subscription.reports_limit - subscription.reports_remaining} / ${
            subscription.reports_limit
          } Reports run this period.`}</Typography>
        </div>
      </div>
    </Box>

    <Typography>Running this report will subtract from this monthly limit. Use the button below to continue.</Typography>
  </Fragment>
);

export default withStyles(styles)(Component);
