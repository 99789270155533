import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import Container from "./Container";

const CURRENT_YEAR = new Date().getFullYear();

const styles = (theme) => ({
  root: {
    borderTop: `3px solid ${theme.palette.secondary.main}`,
    padding: theme.spacing(6)
  },
  link: {
    display: "inline-block",
    padding: theme.spacing(1, 0),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    textDecoration: "none",
    fontSize: 20
  },
  copyright: {
    fontSize: 20,
    marginTop: theme.spacing(1)
  }
});

function Footer({ classes }) {
  return (
    <footer className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.copyright}>{`Copyright ${CURRENT_YEAR}`}</Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);
