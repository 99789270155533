import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import { useForm } from "react-hook-form";
import { withStyles } from "@material-ui/core/styles";

import sharedStyles from "./styles";
import { useGlobalUI } from "context/globalUI";
import { useAPI, RESOURCES } from "utils/api";
import { useAuth } from "utils/auth";
import Spinner from "components/Spinner";
import AppBar from "components/AppBar";
import Container from "components/Container";
import Typography from "components/Typography";
import Card from "components/Card";
import PageTitle from "components/PageTitle";

const styles = (theme) => ({
  ...sharedStyles(theme),
  announcementIcon: {
    display: "inline-block",
    position: "relative",
    top: 8,
    marginRight: theme.spacing(1.5)
  },
  iconTextInline: {
    display: "inline"
  },
  section: {
    marginTop: theme.spacing(4)
  }
});

const VALIDATION_RULES = {
  current_password: {
    required: true
  },
  password: {
    required: true,
    minLength: {
      value: 8,
      message: "Must be 8 characters mimumum"
    }
  },
  password2: {
    required: true,
    minLength: {
      value: 8,
      message: "Must be 8 characters mimumum"
    }
  }
};

function Account({ classes }) {
  const { user } = useAuth();

  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState(user.email);
  const [emailPassword, setEmailPassword] = React.useState("");

  const { register, errors, watch, control, reset, handleSubmit } = useForm({ mode: "onBlur" });
  const data = watch();

  const api = useAPI();
  const auth = useAuth();
  const { snackbar } = useGlobalUI();

  const handleEmailChange = ({ target }) => {
    setEmail(target.value);
  };

  const handleEmailPasswordChange = ({ target }) => {
    setEmailPassword(target.value);
  };

  const handleEmailSubmit = async () => {
    setLoading(true);

    try {
      await api.callWithAuth("POST", RESOURCES.changeEmail, {
        data: {
          email,
          password: emailPassword
        }
      });
    } catch (e) {
      snackbar.open({ message: e.message, color: "error" });
      return;
    } finally {
      setLoading(false);
    }

    snackbar.open({ message: "Please check your inbox to confirm new email address.", color: "success" });
    auth.logout();
  };

  const handlePasswordSubmit = async (data) => {
    setLoading(true);

    try {
      await api.callWithAuth("POST", RESOURCES.changePassword, {
        data: {
          current_password: data.current_password,
          password: data.password,
          password2: data.password2
        }
      });
    } catch (e) {
      snackbar.open({ message: e.message, color: "error" });
      return;
    } finally {
      setLoading(false);
    }

    reset({ current_password: "", password: "", password2: "" });
    snackbar.open({ message: "The password has been changed successfully.", color: "success" });
  };

  return (
    <Fragment>
      <AppBar />

      <Container className={classes.container} maxWidth="md">
        <PageTitle crumbs={["Profile", "My Account"]}>My Account</PageTitle>

        <Card>
          {/* <div className={classes.form}> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">Change Account Email Address</Typography>
            </Grid>
            <Grid item xs={12}>
              <AnnouncementIcon className={classes.announcementIcon} color="primary" />
              <Typography className={classes.iconTextInline} variant="body1">
                To change your current email address, please enter your new email address and your current password below. You
                will receive an email at your new email address with a link to confirm the change.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="email"
                value={email}
                onChange={handleEmailChange}
                error={!email}
                label="Email"
                variant="outlined"
                type="email"
                margin="normal"
                color="secondary"
                empty={!email}
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="emailPassword"
                type="password"
                value={emailPassword}
                onChange={handleEmailPasswordChange}
                label="Current Password"
                helperText="Please type in your current password to request email address change"
                variant="outlined"
                margin="normal"
                color="secondary"
                empty={!emailPassword}
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={3}></Grid>
            <Grid item xs={9}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                size="large"
                className={classes.actionButton}
                onClick={handleEmailSubmit}
                disabled={loading || !email || !emailPassword || email === user.email}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Card>

        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">Change Password</Typography>
            </Grid>
            <Grid item xs={12}>
              <AnnouncementIcon className={classes.announcementIcon} color="primary" />
              <Typography className={classes.iconTextInline} paragraph>
                Your new password must meet these minimum requirements:
                <ul style={{ marginBottom: 0 }}>
                  <li>Minimum of 8 characters</li>
                  <li>At least 1 uppercase letter</li>
                  <li>At least 1 number</li>
                  <li>At least 1 special character (!@#&)</li>
                </ul>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="current_password"
                type="password"
                inputRef={register(VALIDATION_RULES.current_password)}
                error={!!errors.current_password}
                helperText={errors.current_password && errors.current_password.message}
                label="Your Current Password"
                variant="outlined"
                className={classes.textField}
                margin="normal"
                color="secondary"
                empty={!data.current_password}
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="password"
                type="password"
                inputRef={register(VALIDATION_RULES.password)}
                error={!!errors.password}
                helperText={errors.password && errors.password.message}
                label="New Password"
                variant="outlined"
                className={classes.textField}
                margin="normal"
                color="secondary"
                empty={!data.password}
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="password2"
                type="password"
                inputRef={register(VALIDATION_RULES.password2)}
                error={!!errors.password2}
                helperText={errors.password2 && errors.password2.message}
                label="Confirm New Password"
                variant="outlined"
                className={classes.textField}
                margin="normal"
                color="secondary"
                empty={!data.password2}
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={3}></Grid>
            <Grid item xs={9}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                size="large"
                className={classes.actionButton}
                onClick={handleSubmit(handlePasswordSubmit)}
                disabled={loading || Object.keys(errors).length || !(data.password && data.password2 && data.current_password)}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          {/* </div> */}
        </Card>
      </Container>
    </Fragment>
  );
}

export default withStyles(styles)(Account);
