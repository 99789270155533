import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { useAPI } from "utils/api";
import Modal from "components/Modal";

const EditAddressModal = ({ onCancel, onConfirm, records, loading }) => (
  <Modal
    open={true}
    title="Edit Saved Contact"
    actions={
      <Fragment>
        <Button variant="text" onClick={onCancel} disabled={loading}>
          Cancel
        </Button>

        <Button color="primary" variant="text" onClick={onConfirm} disabled={loading}>
          Confirm
        </Button>
      </Fragment>
    }
  >
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography paragraph>
          You're about to edit the contact which is in use by the entities from the list below. If you proceed, the contact will
          be automatically updated for each record.
        </Typography>
      </Grid>
    </Grid>

    {records && records.length && (
      <List dense>
        {records.map((record) => (
          <ListItem key={record.entity_name}>
            <ListItemText
              primary={record.entity_name}
              secondary={record.entity_type === "property_owner" ? "Property Owner Contact" : "Retailer Contact"}
            />
          </ListItem>
        ))}
      </List>
    )}
  </Modal>
);

export default EditAddressModal;
