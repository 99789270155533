import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { useAPI } from "utils/api";
import Modal from "components/Modal";

const DeleteAddressModal = ({ onCancel, onConfirm, loading, records }) => (
  <Modal
    open={true}
    title="Delete Saved Contact"
    actions={
      <Fragment>
        <Button variant="text" onClick={onCancel} disabled={loading}>
          Cancel
        </Button>

        <Button color="primary" variant="text" onClick={onConfirm} disabled={loading || !!(records && records.length)}>
          Delete
        </Button>
      </Fragment>
    }
  >
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography paragraph>
          {records && records.length
            ? "The contact you're trying to delete is in use by the entities from the list below. In order to delete this contact you will have to unlink the contact manually."
            : "Are you sure you want to delete this contact?"}
        </Typography>

        {records && records.length && (
          <List dense>
            {records.map((record) => (
              <ListItem key={record.entity_name}>
                <ListItemText
                  primary={record.entity_name}
                  secondary={record.entity_type === "property_owner" ? "Property Owner Contact" : "Retailer Contact"}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Grid>
    </Grid>
  </Modal>
);

export default DeleteAddressModal;
