import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";

import { useAuth } from "utils/auth";
import { overriddenUserId, leaveOverrideUserIdMode } from "utils/api";

import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  loggedInAdmin: {
    backgroundColor: theme.palette.error.main,
    color: "#fff",
    cursor: "pointer"
  },
  adminAction: {
    color: "#fff",
    fontWeight: "bold"
  }
});

const LoggedInAsBar = ({ classes }) => {
  const { user } = useAuth();

  if (!overriddenUserId || !user) {
    return null;
  }

  return (
    <Snackbar
      className={classes.loggedInAdmin}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      open={true}
      ContentProps={{
        "aria-describedby": "message-id",
        classes: { root: classes.loggedInAdmin }
      }}
      message={<span id="message-id">{`Signed in as: ${user.first_name} ${user.last_name} (${user.user_types})`}</span>}
      action={
        <div>
          <Button className={classes.adminAction} onClick={leaveOverrideUserIdMode}>
            Leave
          </Button>
        </div>
      }
    />
  );
};

export default withStyles(styles)(LoggedInAsBar);
