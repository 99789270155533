import React, { Fragment } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useForm, Controller } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

import { useAPI, RESOURCES } from "utils/api";
import { useAuth } from "utils/auth";
import history from "utils/history";
import Spinner from "components/Spinner";
import OutlinedSelect from "components/OutlinedSelect";
import { USStates } from "utils/constants";

const USStatesArray = USStates.split(",");

const styles = (theme) => ({
  buttonContainer: {
    margin: theme.spacing(3, 0, 2),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& > *": {
      marginLeft: theme.spacing(1)
    }
  },
  cardElementWrapper: {
    borderRadius: 4,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    padding: "10.5px 14px"
  }
});

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: '"Roboto", "Sans", "Helvetica Neue", sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.87)",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#F28C11"
    }
  }
};

const VALIDATION_RULES = {
  first_name: { required: true },
  last_name: { required: true },
  address: { required: true },
  address2: { required: false },
  city: { required: true },
  state: { required: true },
  zip: {
    required: true,
    pattern: {
      value: /^[0-9]{5}(\-[0-9]{4})?$/,
      message: "Invalid zip code"
    }
  }
};

const Component = ({ classes, paymentData, redirectUrl, onSuccess, loading, onCancel }) => {
  const [error, setError] = React.useState(null);
  const [paymentProcessing, setPaymentProcessing] = React.useState(false);
  const [paymentProcessed, setPaymentProcessed] = React.useState(false);

  const { register, errors, watch, control, reset, handleSubmit } = useForm({ mode: "onBlur" });
  const data = watch();

  const api = useAPI();
  const auth = useAuth();
  const stripe = useStripe();
  const elements = useElements();

  const onSubmit = async (data) => {
    setPaymentProcessing(true);

    const card = elements.getElement(CardElement);

    let result = await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: {
        name: `${data.first_name} ${data.last_name}`,
        email: auth.user.email,
        address: {
          country: "US",
          city: data.city,
          line1: data.address,
          line2: data.address2,
          postal_code: data.zip,
          state: data.state
        }
      }
    });

    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
      setPaymentProcessing(false);
      return;
    }

    setError(null);

    try {
      result = await api.callWithAuth("POST", RESOURCES.accountSubscriptionPay, {
        data: { ...paymentData, payment_method_id: result.paymentMethod.id }
      });
    } catch (e) {
      setError(e.message);
      setPaymentProcessing(false);
      return;
    }

    setPaymentProcessed(true);
    setPaymentProcessing(false);

    if (redirectUrl) {
      setTimeout(() => {
        history.push(redirectUrl);
      }, 2000);
    }
  };

  const handleChange = (evt) => {
    if (evt.error) {
      setError(evt.error.message);
    } else {
      setError(null);
    }
  };

  const isFormValid = !Object.keys(errors).length;

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6" gutterBottom>
          Billing Address
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="first_name"
              inputRef={register(VALIDATION_RULES.first_name)}
              label="First Name"
              variant="outlined"
              margin="dense"
              color="secondary"
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="last_name"
              inputRef={register(VALIDATION_RULES.last_name)}
              label="Last Name"
              variant="outlined"
              margin="dense"
              color="secondary"
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="address"
              inputRef={register(VALIDATION_RULES.address)}
              error={!!errors.address}
              label="Address"
              variant="outlined"
              margin="dense"
              color="secondary"
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="address2"
              inputRef={register}
              label="Address line 2 (suite, apt, unit#)"
              variant="outlined"
              margin="dense"
              color="secondary"
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="city"
              inputRef={register(VALIDATION_RULES.city)}
              error={!!errors.city}
              label="City"
              variant="outlined"
              margin="dense"
              color="secondary"
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              as={
                <OutlinedSelect
                  name="state"
                  error={!!errors.state}
                  label="State"
                  margin="dense"
                  color="secondary"
                  required
                  fullWidth
                >
                  {USStatesArray.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </OutlinedSelect>
              }
              name="state"
              value={data.state}
              defaultValue=""
              control={control}
              rules={VALIDATION_RULES.state}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="zip"
              inputRef={register(VALIDATION_RULES.zip)}
              error={!!errors.zip}
              helperText={errors.zip && errors.zip.message}
              label="Zip"
              variant="outlined"
              margin="dense"
              color="secondary"
              required
              fullWidth
            />
          </Grid>
        </Grid>

        <Box mt={1}>
          <Typography variant="h6" gutterBottom>
            Credit Card Details
          </Typography>

          <div className={classes.cardElementWrapper}>
            <CardElement options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
          </div>
        </Box>

        {!isFormValid || !!error ? (
          <Box mt={2}>
            <Alert severity="error">{error ? error : "Please fill in required fields to continue"}</Alert>
          </Box>
        ) : null}

        {paymentProcessed && (
          <Box mt={2}>
            <Alert severity="success">{`Thank you for your payment. ${
              redirectUrl ? "Redirecting you to your reports shortly..." : ""
            }`}</Alert>
          </Box>
        )}

        <div className={classes.buttonContainer}>
          {paymentProcessing && <Spinner size={16} />}

          {paymentProcessed ? (
            <Button variant="contained" color="secondary" onClick={onSuccess} disabled={!!redirectUrl}>
              Close
            </Button>
          ) : (
            <Fragment>
              <Button variant="text" onClick={onCancel} disabled={loading}>
                Cancel
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={loading || !stripe || !!error || !isFormValid || paymentProcessing}
              >
                Pay
              </Button>
            </Fragment>
          )}
        </div>
      </form>
    </Fragment>
  );
};

export default withStyles(styles)(Component);
