import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  box: {
    background: theme.palette.gray.dark,
    color: "#FFF",
    padding: theme.spacing(2, 3),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1)
    }
  },
  title: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium
  }
});

const Component = ({ classes, retailer }) => (
  <Fragment>
    <Box className={classes.box} p={1} mb={2} borderRadius="borderRadius">
      <Typography className={classes.title} gutterBottom>
        Sorry, no matches right now
      </Typography>

      <Typography variant="body2">{`Our system doesn't show any matches for your ${
        retailer ? "requirements" : "property"
      } yet.`}</Typography>
    </Box>

    <Typography variant="h6" gutterBottom>
      No matches were found...  Now what? 
    </Typography>

    <Typography>Real Prop X is designed to automatically send you an email alert when a new match is entered into the system, so check your inbox and visit us again soon!</Typography>
  </Fragment>
);

export default withStyles(styles)(Component);
