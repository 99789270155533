import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import GoogleAnalytics from "context/GoogleAnalytics";
import ErrorBoundary from "components/ErrorBoundary";
import AsyncComponent from "components/HOC/AsyncComponent";
import SnackBar from "components/Snackbar";
import ConfirmDeleteContactModal from "components/ConfirmDeleteContactModal";
import UserFeedbackModal from "components/UserFeedbackModal";
import WelcomeModal from "components/WelcomeModal";
import history from "./utils/history";
import theme from "./styles/theme";
import { AuthProvider } from "./utils/auth";
import { GlobalUIProvider } from "./context/globalUI";
import { UserFeedbackProvider } from "./context/userFeedback";
import VersionCheck from "components/VersionCheck";
import { overriddenUserId } from "utils/api";
import LoggedInAsBar from "components/LoggedInAsBar";
import ProvideFeedbackBar from "components/ProvideFeedbackBar";
import MainApp from "./apps/Main";
import "./styles/global.css";

const AdminAppAsync = AsyncComponent(() => import("apps/Admin"));
const RetailerAppAsync = AsyncComponent(() => import("apps/Retailer"));
const OwnerAppAsync = AsyncComponent(() => import("apps/Owner"));

function Main() {
  return (
    <ErrorBoundary>
      <GoogleAnalytics.Provider>
        <ThemeProvider theme={theme}>
          <GlobalUIProvider>
            <UserFeedbackProvider>
              <AuthProvider>
                <VersionCheck />
                <SnackBar />
                <ConfirmDeleteContactModal />
                <UserFeedbackModal />
                <WelcomeModal />
                <ProvideFeedbackBar />
                {overriddenUserId && <LoggedInAsBar />}

                <Router history={history}>
                  <GoogleAnalytics.RouteTracker />

                  <Switch>
                    <Route path="/admin" component={AdminAppAsync} />
                    <Route path="/retailers" component={RetailerAppAsync} />
                    <Route path="/properties" component={OwnerAppAsync} />
                    <Route path="/" component={MainApp} />
                  </Switch>
                </Router>
              </AuthProvider>
            </UserFeedbackProvider>
          </GlobalUIProvider>
        </ThemeProvider>
      </GoogleAnalytics.Provider>
    </ErrorBoundary>
  );
}

export default Main;
