import React, { useState, useEffect, useContext, createContext } from "react";
import debounce from "lodash.debounce";

const globalUIContext = createContext();

export function GlobalUIProvider({ children }) {
  const ui = useProvideGlobalUI();
  return <globalUIContext.Provider value={ui}>{children}</globalUIContext.Provider>;
}

export const useGlobalUI = () => {
  return useContext(globalUIContext);
};

function useProvideGlobalUI() {
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    color: "",
    position: null
  });
  const [deviceScreen, setDeviceScreen] = useState({
    isMobile: false,
    isDesktop: false,
    width: null
  });
  const [confirmDelete, setConfirmDelete] = useState({
    modalOpen: false,
    itemToDelete: null,
    deleteMethod: null
  });

  function openSnackbar({ message, color, position }) {
    setSnackbar({
      message,
      color,
      position,
      isOpen: true
    });
  }

  function closeSnackbar() {
    setSnackbar({
      ...snackbar,
      isOpen: false
    });
  }

  const getDeviceWidth = debounce(() => {
    let width = window.innerWidth;
    setDeviceScreen({
      width,
      isMobile: width <= 400 ? true : false,
      isDesktop: width >= 401 ? true : false
    });
  }, 1000);

  function openConfirmModal(itemToDelete, deleteMethod) {
    setConfirmDelete({ modalOpen: true, itemToDelete, deleteMethod });
  }
  function closeConfirmModal() {
    setConfirmDelete({ modalOpen: false, itemToDele: null, deleteMethod: null });
  }

  return {
    snackbar: {
      open: openSnackbar,
      close: closeSnackbar,
      ...snackbar
    },
    deviceScreen: {
      getDeviceWidth,
      ...deviceScreen
    },
    confirmDelete: {
      openModal: openConfirmModal,
      closeModal: closeConfirmModal,
      ...confirmDelete
    }
  };
}
