import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = (theme) => ({
  paper: {
    minWidth: 600,
    [theme.breakpoints.down("xs")]: {
      minWidth: "95%"
    }
  },
  title: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2, 2)
    }
  },
  content: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2, 2)
    }
  },
  buttonContainer: {
    margin: theme.spacing(3, 2, 2)
  }
});

const Modal = ({ classes, title, children, actions, contentPadding, ...props }) => {
  return (
    <Dialog classes={{ paper: classes.paper }} {...props}>
      {title && <DialogTitle classes={{ root: classes.title }}>{title}</DialogTitle>}
      <DialogContent classes={{ root: classes.content }}>{children}</DialogContent>
      {actions && <DialogActions className={classes.buttonContainer}>{actions}</DialogActions>}
    </Dialog>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  actions: PropTypes.node
};

export default withStyles(styles)(Modal);
