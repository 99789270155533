import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";

import sharedStyles from "../styles";
import { useGlobalUI } from "context/globalUI";
import { useAPI, RESOURCES } from "utils/api";
import { useAuth, USER_TYPES } from "utils/auth";
import Spinner from "components/Spinner";
import AppBar from "components/AppBar";
import Card from "components/Card";
import Container from "components/Container";
import Typography from "components/Typography";
import PageTitle from "components/PageTitle";
import Switch from "components/Switch";

const styles = (theme) => ({
  ...sharedStyles(theme),
  description: {
    // padding: theme.spacing(3),
    width: "80%",
    margin: theme.spacing(3, 0, 2, 0),
    "& > *": {
      fontSize: 16,
      fontWeight: theme.typography.fontWeightLight,
      letterSpacing: ".1em"
    }
  },
  notifCategorySection: {
    width: "95%",
    margin: `0 0 ${theme.spacing(6)}px auto`
  },
  category: {
    marginBottom: theme.spacing(3)
  },
  categoryHeader: {
    borderBottom: `3px solid ${theme.palette.secondary.main}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2)
  },
  categoryTitle: {
    marginTop: theme.spacing(3)
  },
  individualPrefsTitle: {
    margin: theme.spacing(0, 2, 0.5, 0),
    fontSize: 18,
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: 17,
      lineHeight: 1.2
    }
  },
  categoryButtonContainer: {
    display: "flex"
  },
  categoryHeaderIcon: {
    flexGrow: 1,
    textAlign: "right"
  },
  allSwitchContainer: {
    marginTop: theme.spacing(1)
    // marginBottom: theme.spacing(2)
  },
  disabled: {
    opacity: 0.3
  }
});

function Preferences({ classes }) {
  const [retailerAssets, setRetailerAssets] = React.useState([]);
  const [brokerAssets, setBrokerAssets] = React.useState([]);
  const [retailerNotifPrefs, setRetailerNotifPrefs] = React.useState();
  const [brokerNotifPrefs, setBrokerNotifPrefs] = React.useState();
  const [retailersCollapsed, setRetailersCollapsed] = React.useState(false);
  const [brokersCollapsed, setBrokersCollapsed] = React.useState(false);
  const [retailersCollapseDisabled, setRetailersCollapseDisabled] = React.useState(false);
  const [brokersCollapseDisabled, setBrokersCollapseDisabled] = React.useState(false);
  const [switched, setSwitched] = React.useState(false);

  const api = useAPI();
  const auth = useAuth();
  const { control } = useForm();

  React.useEffect(() => {
    fetchNotificationPrefs();
  }, [switched]);

  React.useEffect(() => {
    fetchAssets();
  }, []);

  const fetchAssets = async () => {
    if (auth.userType[USER_TYPES.Retailer]) {
      const { data } = await api.callWithAuth("GET", RESOURCES.retailers, { params: { start: 0, limit: 999 } });
      setRetailerAssets(data);
    }
    if (auth.userType[USER_TYPES.Broker]) {
      const { data } = await api.callWithAuth("GET", RESOURCES.properties, { params: { start: 0, limit: 999 } });
      setBrokerAssets(data);
    }
  };

  const fetchNotificationPrefs = async () => {
    const response = await api.callWithAuth("GET", RESOURCES.prefsNotifications);

    if (auth.userType[USER_TYPES.Broker]) {
      setBrokerNotifPrefs(response.property_new_matches);
      handleNotifCollapse(response.property_new_matches);
    }
    if (auth.userType[USER_TYPES.Retailer]) {
      setRetailerNotifPrefs(response.retailer_new_matches);
      handleNotifCollapse(response.retailer_new_matches);
    }
  };

  const handleNotifCollapse = (prefs) => {
    if (prefs.disabled && !prefs.object_ids) {
      if (prefs.id === 2) {
        setBrokersCollapseDisabled(true);
        setBrokersCollapsed(true);
        return;
      } else if (prefs.id === 3) {
        setRetailersCollapseDisabled(true);
        setRetailersCollapsed(true);
        return;
      }
    } else {
      if (prefs.id === 2) {
        setBrokersCollapseDisabled(false);
        setBrokersCollapsed(false);
      } else if (prefs.id === 3) {
        setRetailersCollapseDisabled(false);
        setRetailersCollapsed(false);
      }
    }
  };

  const onSwitchChange = async (checked, id, notificationID) => {
    let operation;
    let body = {};

    if (id) {
      body.object_id = id;
    }
    if (!checked) operation = "enable";
    if (checked) operation = "disable";

    const URL = `${RESOURCES.prefsNotifications}/${notificationID}/${operation}`;

    await api.callWithAuth("POST", URL, { data: body });
    setSwitched(!switched);
  };

  const assetTypePlural = auth.userType[USER_TYPES.Broker] ? "properties" : "tenant requirements";
  const assetTypePluralCaps = auth.userType[USER_TYPES.Broker] ? "Properties" : "Tenant Requirements";
  const assetTypeSingular = auth.userType[USER_TYPES.Broker] ? "property" : "tenant requirement";
  const assetTypeSingularCaps = auth.userType[USER_TYPES.Broker] ? "Property" : "Tenant Requirement";

  return (
    <Fragment>
      <AppBar />

      <Container className={classes.container} maxWidth="md">
        <PageTitle crumbs={["Profile", "Preferences"]}>Preferences</PageTitle>
        <Card>
          <Typography variant="h4">New Matches Notifications</Typography>
          <Typography className={classes.description} variant="body1">
            Set all or none of your {assetTypePlural} to receive "new matches emails", or expand the form below to apply settings
            to each {assetTypeSingular} individually. Changes are saved automatically.
          </Typography>

          {retailerNotifPrefs && (
            <Fragment>
              <Typography variant="h6" className={classes.categoryTitle}>
                Tenant Requirements
              </Typography>
              <div className={classes.notifCategorySection}>
                <div className={classes.allSwitchContainer}>
                  <Switch
                    name="all-tenant-requirements"
                    label="All"
                    control={control}
                    checked={!retailerNotifPrefs.disabled}
                    handleChange={onSwitchChange}
                    notificationID={retailerNotifPrefs.id}
                  />
                </div>

                <div className={`${classes.categoryHeader} ${retailersCollapseDisabled ? classes.disabled : ""}`}>
                  <Typography color="secondary" className={classes.individualPrefsTitle}>
                    Set Individual Preferences
                  </Typography>
                  <div className={classes.categoryButtonContainer}>
                    <div className={classes.categoryHeaderIcon}>
                      <IconButton onClick={() => setRetailersCollapsed(!retailersCollapsed)} disabled={retailersCollapseDisabled}>
                        {retailersCollapsed ? <ArrowDropUpIcon fontSize="large" /> : <ArrowDropDownIcon fontSize="large" />}
                      </IconButton>
                    </div>
                  </div>
                </div>

                <Collapse in={!retailersCollapsed}>
                  {retailerAssets &&
                    retailerNotifPrefs &&
                    retailerAssets.map((item) => {
                      let checked = retailerNotifPrefs.object_ids
                        ? retailerNotifPrefs.object_ids.includes(item.id)
                          ? false
                          : true
                        : true;

                      return (
                        <div style={{ width: "95%", margin: "0 auto" }} key={item.id}>
                          <Switch
                            id={item.id}
                            name={item.name}
                            label={item.name}
                            subText={item.territory?.join(", ")}
                            control={control}
                            checked={checked}
                            handleChange={onSwitchChange}
                            notificationID={retailerNotifPrefs.id}
                          />
                        </div>
                      );
                    })}
                </Collapse>
              </div>
            </Fragment>
          )}

          {brokerNotifPrefs && (
            <Fragment>
              <Typography variant="h6" className={classes.categoryTitle}>
                Properties
              </Typography>
              <div className={classes.notifCategorySection}>
                <div className={classes.allSwitchContainer}>
                  <Switch
                    name="all-properties"
                    label={`All`}
                    control={control}
                    checked={!brokerNotifPrefs.disabled && !brokerNotifPrefs.object_ids}
                    handleChange={onSwitchChange}
                    notificationID={brokerNotifPrefs.id}
                  />
                </div>

                <div className={`${classes.categoryHeader} ${brokersCollapseDisabled ? classes.disabled : ""}`}>
                  <Typography color="secondary" className={classes.individualPrefsTitle}>
                    Set Individual Preferences
                  </Typography>
                  <div className={classes.categoryButtonContainer}>
                    <div className={classes.categoryHeaderIcon}>
                      <IconButton onClick={() => setBrokersCollapsed(!brokersCollapsed)} disabled={brokersCollapseDisabled}>
                        {brokersCollapsed ? <ArrowDropUpIcon fontSize="large" /> : <ArrowDropDownIcon fontSize="large" />}
                      </IconButton>
                    </div>
                  </div>
                </div>

                <Collapse in={!brokersCollapsed}>
                  {brokerAssets &&
                    brokerNotifPrefs &&
                    brokerAssets.map((item) => {
                      let checked = brokerNotifPrefs.object_ids
                        ? brokerNotifPrefs.object_ids.includes(item.id)
                          ? false
                          : true
                        : true;

                      return (
                        <div style={{ width: "95%", margin: "0 auto" }} key={item.id}>
                          <Switch
                            id={item.id}
                            name={item.name}
                            label={item.name}
                            subText={item.territory?.join(", ")}
                            control={control}
                            checked={checked}
                            handleChange={onSwitchChange}
                            notificationID={brokerNotifPrefs.id}
                          />
                        </div>
                      );
                    })}
                </Collapse>
              </div>
            </Fragment>
          )}
        </Card>
      </Container>
    </Fragment>
  );
}

export default withStyles(styles)(Preferences);
