import React from "react";
import MuiTextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  valid: {
    position: "relative"
  },
  invalid: {
    position: "relative"
  },
  icon: {
    // position: "absolute",
    left: "-30px",
    top: "50%",

    "& > *": {
      fontSize: "24px"
    }
  },
  validIcon: {
    color: theme.palette.success.main
  },
  leftPadding: {
    // paddingLeft: 30,
    width: "100%"
  }
});

const TextField = ({ classes, empty, error, className, InputProps, leftPadding, required, ...props }) => {
  const classNames = [];
  const PatchedInputProps = { ...InputProps };

  if (className) {
    classNames.push(className);
  }

  if (!error && !empty) {
    classNames.push(classes.valid);
    PatchedInputProps.startAdornment = (
      <InputAdornment position="start" className={classes.icon}>
        <CheckCircleOutlineIcon className={classes.validIcon} />
      </InputAdornment>
    );
  } else if (error) {
    classNames.push(classes.invalid);
    PatchedInputProps.startAdornment = (
      <InputAdornment position="start" className={classes.icon}>
        <CancelIcon color="error" />
      </InputAdornment>
    );
  }

  return (
    <div className={leftPadding ? classes.leftPadding : ""}>
      <MuiTextField
        required={required}
        className={classNames.join(" ")}
        InputProps={PatchedInputProps}
        error={error}
        {...props}
      />
    </div>
  );
};

export default withStyles(styles)(TextField);
