import React, { useState, useEffect, useContext, createContext, useMemo } from "react";

import { sentrySetScopeUsername } from "utils/sentry";
import { useAnalytics } from "context/GoogleAnalytics";
import { RESOURCES, call, overriddenUserId, leaveOverrideUserIdMode } from "./api";
import history from "./history";

const LOCAL_STORAGE_KEY = "authToken";

export const USER_TYPES = {
  Broker: "broker",
  Retailer: "retailer",
  Admin: "admin"
};

const authContext = createContext();

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const analytics = useAnalytics();

  const getToken = () => localStorage[LOCAL_STORAGE_KEY];

  const login = async (email, password) => {
    const result = await call("POST", RESOURCES.login, null, { data: { email, password } });
    if (result.token) {
      sentrySetScopeUsername(email);
      analytics.setUserId(email);
      analytics.trackEvent("login");

      localStorage[LOCAL_STORAGE_KEY] = result.token;
      setUser(result.userInfo);

      history.push("/");
    }
  };

  const logout = () => {
    if (overriddenUserId) {
      // Do not actually logout but leave "Login As" mode
      leaveOverrideUserIdMode();
      return;
    }

    localStorage.removeItem(LOCAL_STORAGE_KEY);
    setUser(null);
    analytics.setUserId(null);

    sentrySetScopeUsername("");

    if (overriddenUserId) {
      // Do not actually logout but leave "Login As" mode
      leaveOverrideUserIdMode();
    }
  };

  const forgotPassword = async (email) => {
    await call("POST", RESOURCES.forgotPassword, null, { data: { email } });
  };

  const resetPassword = async (email, code, password) => {
    await call("POST", RESOURCES.resetPassword, null, { data: { email, auth_token: code, password } });
  };

  const confirmNewEmail = async (code, password) => {
    await call("POST", RESOURCES.changeEmailConfirm, null, { data: { new_email_token: code, password } });
  };

  const verifyEmail = async (code) => {
    await call("POST", RESOURCES.verifyEmail, null, { data: { verify_token: code } });
  };

  const verifyInviteCode = async (code) => {
    return await call("POST", RESOURCES.verifyInviteCode, null, { data: { invite_code: code } });
  };

  const register = async (data) => {
    await call("POST", RESOURCES.register, null, { data });
  };

  const updateUserInfo = async () => {
    try {
      const result = await call("GET", RESOURCES.userInfo, getToken());
      setUser(result);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    const token = getToken();

    if (!token) {
      setUser(null);
      setLoading(false);
    } else {
      call("GET", RESOURCES.userInfo, token)
        .then((result) => {
          sentrySetScopeUsername(result.email);
          analytics.setUserId(result.email);

          setUser(result);
          setLoading(false);
        })
        .catch((err) => {
          sentrySetScopeUsername("");

          logout();
          setLoading(false);
        });
    }
  }, []);

  const userType = useMemo(
    () => ({
      [USER_TYPES.Broker]: user && user.user_types.includes(USER_TYPES.Broker),
      [USER_TYPES.Retailer]: user && user.user_types.includes(USER_TYPES.Retailer),
      [USER_TYPES.Admin]: user && user.user_types.includes(USER_TYPES.Admin)
    }),
    [user]
  );

  return {
    user,
    userType,
    loading,
    login,
    logout,
    register,
    forgotPassword,
    resetPassword,
    confirmNewEmail,
    verifyEmail,
    verifyInviteCode,
    getToken,
    updateUserInfo
  };
}
