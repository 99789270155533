import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import MaskedInput from "react-input-mask";

import sharedStyles from "../styles";
import { useGlobalUI } from "context/globalUI";
import { useAPI, RESOURCES } from "utils/api";
import { useAuth } from "utils/auth";
import Spinner from "components/Spinner";
import AppBar from "components/AppBar";
import Container from "components/Container";
import TextField from "components/TextField";
import Typography from "components/Typography";
import Card from "components/Card";
import PageTitle from "components/PageTitle";

const validatePhone = (value) => {
  return !value || /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/.test(value);
};

const styles = (theme) => ({
  ...sharedStyles(theme),
  actionButton: {
    width: 300,
    float: "right",
    fontSize: 20,
    marginTop: theme.spacing(3)
  }
});

const VALIDATION_RULES = {
  first_name: {
    required: true
  },
  last_name: {
    required: true
  },
  phone: {
    required: false,
    validate: validatePhone
  },
  phone_ext: {
    required: false,
    pattern: {
      value: /^[0-9]*$/,
      message: "Only numbers allowed"
    }
  },
  cell: {
    required: false,
    validate: validatePhone
  },
  company_name: {
    required: false
  }
};

function Profile({ classes }) {
  const [loading, setLoading] = React.useState(true);

  const { register, errors, watch, control, reset, handleSubmit } = useForm({ mode: "onBlur" });
  const data = watch();

  const api = useAPI();
  const auth = useAuth();
  const { snackbar } = useGlobalUI();

  React.useEffect(() => {
    async function load() {
      let data;

      try {
        data = await api.callWithAuth("GET", RESOURCES.accountProfile);
      } catch (e) {
        snackbar.open({ message: e.message, color: "error" });
        return;
      }

      setLoading(false);
      reset(data);
    }

    load();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      await api.callWithAuth("PUT", RESOURCES.accountProfile, { data });
    } catch (e) {
      snackbar.open({ message: e.message, color: "error" });
      return;
    } finally {
      setLoading(false);
    }

    snackbar.open({ message: "The profile has been updated successfully.", color: "success" });

    auth.updateUserInfo();
  };

  return (
    <Fragment>
      <AppBar />

      <Container className={classes.container} maxWidth="md">
        <PageTitle crumbs={["Profile", "My Profile"]}>My Profile</PageTitle>

        <Card>
          <Typography className={classes.helperText} align="center" gutterBottom>
            Use the inputs below to enter or update your account information. This information only affects account and does not
            effect your properties or requirements.
          </Typography>

          <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="first_name"
                  inputRef={register(VALIDATION_RULES.first_name)}
                  disabled={loading}
                  label="First Name"
                  variant="outlined"
                  margin="normal"
                  color="secondary"
                  error={!!errors.first_name}
                  empty={!data.first_name}
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="last_name"
                  inputRef={register(VALIDATION_RULES.last_name)}
                  disabled={loading}
                  label="Last Name"
                  variant="outlined"
                  margin="normal"
                  color="secondary"
                  error={!!errors.last_name}
                  empty={!data.last_name}
                  leftPadding
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  as={
                    <MaskedInput mask="(999) 999-9999">
                      {(inputProps) => (
                        <TextField
                          inputProps={inputProps}
                          error={!!errors.phone}
                          helperText={errors.phone && errors.phone.message}
                          label="Phone"
                          variant="outlined"
                          type="tel"
                          margin="normal"
                          color="secondary"
                          empty={!data.phone}
                          fullWidth
                        />
                      )}
                    </MaskedInput>
                  }
                  name="phone"
                  value={data.phone || " "}
                  disabled={loading}
                  defaultValue=""
                  control={control}
                  rules={VALIDATION_RULES.phone}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="phone_ext"
                  inputRef={register(VALIDATION_RULES.phone_ext)}
                  disabled={loading}
                  error={!!errors.phone_ext}
                  helperText={errors.phone_ext && errors.phone_ext.message}
                  label="Phone Extension"
                  variant="outlined"
                  margin="normal"
                  color="secondary"
                  empty={!data.phone_ext}
                  fullWidth
                  leftPadding
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  as={
                    <MaskedInput mask="(999) 999-9999">
                      {(inputProps) => (
                        <TextField
                          inputProps={inputProps}
                          error={!!errors.cell}
                          helperText={errors.cell && errors.cell.message}
                          label="Cell Phone"
                          variant="outlined"
                          type="tel"
                          margin="normal"
                          color="secondary"
                          empty={!data.cell}
                          fullWidth
                        />
                      )}
                    </MaskedInput>
                  }
                  name="cell"
                  value={data.cell || " "}
                  disabled={loading}
                  defaultValue=""
                  control={control}
                  rules={VALIDATION_RULES.cell}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="company_name"
                  inputRef={register(VALIDATION_RULES.company_name)}
                  disabled={loading}
                  error={!!errors.company_name}
                  helperText={errors.company_name && errors.company_name.message}
                  label="Company Name"
                  variant="outlined"
                  margin="normal"
                  color="secondary"
                  empty={!data.company_name}
                  fullWidth
                  leftPadding
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={3}></Grid>
              <Grid item xs={9}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  className={classes.actionButton}
                  onClick={handleSubmit(onSubmit)}
                  disabled={loading || !!Object.keys(errors).length}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Container>
    </Fragment>
  );
}

export default withStyles(styles)(Profile);
