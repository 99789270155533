import React, { Fragment, useState } from "react";
import Button from "@material-ui/core/Button";
import { useForm, Controller } from "react-hook-form";
import { RadioGroup, FormControlLabel, FormLabel, withStyles, Radio } from "@material-ui/core";

import Modal from "components/Modal";
import Typography from "components/Typography";
import TextField from "components/TextField";
import Spinner from "components/Spinner";
import { useUserFeedback } from "context/userFeedback";
import { useAPI, RESOURCES } from "utils/api";
import { useGlobalUI } from "context/globalUI";
import { useAuth } from "utils/auth";
import getCurrentBrowser from "utils/getBrowser";
import getScreenResolution from "utils/getScreenResolution";

const styles = (theme) => ({
  radioGroupLabel: {
    marginTop: theme.spacing(3)
  },
  radioButton: {
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px 0`
  }
});

function UserFeedbackModal({ classes }) {
  const [loading, setLoading] = useState(false);

  const { register, watch, control, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: { confirm_update: false }
  });
  const data = watch();
  const api = useAPI();
  const { snackbar, deviceScreen } = useGlobalUI();
  const auth = useAuth();
  const {
    userFeedback: { modalIsOpen, closeModal, pageCallback, message }
  } = useUserFeedback();

  const handleSkip = () => {
    closeModal();
    pageCallback();
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const url = RESOURCES.userFeedback;

    const dataToSend = {
      ...data,
      feedback_consensus: data.feedback_consensus === "like",
      page: window.location.href,
      screen_resolution: getScreenResolution(deviceScreen.isMobile)
    };

    try {
      await api.callWithAuth("POST", url, { data: dataToSend });
    } catch (e) {
      snackbar.open({ message: "Something went wrong, please trye again", color: "error" });
      return;
    } finally {
      setLoading(false);
    }

    closeModal();
    pageCallback();
    snackbar.open({ message: "Thank you for submitting feedback!", color: "success" });
  };

  if (!modalIsOpen) {
    return null;
  }

  return (
    <Modal
      open={true}
      title={"Help Us Improve?"}
      actions={
        <Fragment>
          <Button variant="text" onClick={handleSkip}>
            Skip
          </Button>

          {!!loading ? (
            <Spinner />
          ) : (
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={!data.feedback_positive && !data.feedback_negative && !data.feedback_consensus}
            >
              Submit
            </Button>
          )}
        </Fragment>
      }
    >
      <Typography variant="h5">{message ? message : "Let us know what you liked or what could use improvement!"}</Typography>
      <TextField
        name="feedback_positive"
        inputRef={register}
        required={false}
        empty={!data.got_right}
        label="What Did We Get Right?"
        variant="outlined"
        margin="normal"
        color="secondary"
        fullWidth
        multiline
      />
      <TextField
        name="feedback_negative"
        inputRef={register}
        required={false}
        empty={!data.got_wrong}
        label="What Could Be Better?"
        variant="outlined"
        margin="normal"
        color="secondary"
        fullWidth
        multiline
      />
      <section className={classes.likeDislikeGroup}>
        <FormLabel component="legend" className={classes.radioGroupLabel}>
          This Feature
        </FormLabel>
        <Controller
          as={
            <RadioGroup aria-label="gender" row>
              <FormControlLabel value={"like"} control={<Radio className={classes.radioButton} />} label="Like" />
              <FormControlLabel value={"dislike"} control={<Radio className={classes.radioButton} />} label="Dislike" />
            </RadioGroup>
          }
          name="feedback_consensus"
          control={control}
        />
      </section>
    </Modal>
  );
}

export default withStyles(styles)(UserFeedbackModal);
