import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

import AppBar from "components/AppBar";
import Footer from "components/Footer";
import Container from "components/Container";
import Typography from "components/Typography";

const styles = (theme) => ({
  section1: {
    position: "relative",
    paddingTop: theme.spacing(18),
    paddingBottom: theme.spacing(18),
    color: theme.palette.common.white
  },
  section2: {
    position: "relative",
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    color: theme.palette.text.primary
  },
  container: {
    color: "inherit",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  background: {
    position: "absolute",
    zIndex: -2,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  backgroundImage1: {
    backgroundImage: "url(/assets/jumbotron-bg-1x.jpg)",

    "@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)": {
      backgroundImage: "url(/assets/jumbotron-bg-2x.jpg)"
    },

    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      background: "#000",
      opacity: 0.74
    }
  },
  backgroundImage2: {
    backgroundImage: "url(/assets/textured-section-bg.png)"
  },
  button: {
    minWidth: 200,
    padding: theme.spacing(2, 5),
    textTransform: "none",
    fontSize: 18
  },
  button1: {
    color: theme.palette.common.white
  },
  button2: {
    color: theme.palette.common.white
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(10)
    }
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(5),
    fontWeight: "bold",

    "& > div:first-child": {
      textAlign: "center",
      padding: theme.spacing(2, 0),
      color: theme.palette.secondary.main
    },

    "& > *": {
      fontSize: 20,
      lineHeight: 1.33
    }
  },
  itemImage: {
    flexBasis: 130,
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(4),

    "& > img": {
      width: 100
    }
  }
});

function Home({ classes }) {
  return (
    <Fragment>
      <AppBar />

      {/*       <section className={classes.section1}> */}
      {/*         <Container className={classes.container}> */}
      {/*           <Typography color="inherit" align="center" variant="h2" marked="center"> */}
      {/*             Retailers Suited for your Site */}
      {/*           </Typography> */}
      {/*           <Typography color="inherit" align="center" variant="h5" className={classes.h5}> */}
      {/*             Find retailers and restaurants for your property */}
      {/*             <br /> matching demographic and site specific criterion. */}
      {/*           </Typography> */}
      {/*  */}
      {/*           <Button */}
      {/*             color="primary" */}
      {/*             variant="contained" */}
      {/*             size="large" */}
      {/*             className={`${classes.button} ${classes.button1}`} */}
      {/*             component={Link} */}
      {/*             to="/properties" */}
      {/*           > */}
      {/*             Get Started! */}
      {/*           </Button> */}
      {/*         </Container> */}
      {/*  */}
      {/*         <div className={`${classes.background} ${classes.backgroundImage1}`} /> */}
      {/*       </section> */}
      {/*  */}
      {/*       <section className={classes.section2}> */}
      {/*         <Container className={classes.container}> */}
      {/*           <Typography color="inherit" align="center" variant="h3" marked="center"> */}
      {/*             How It Works */}
      {/*           </Typography> */}
      {/*  */}
      {/*           <Grid container spacing={5}> */}
      {/*             <Grid item xs={12} md={4}> */}
      {/*               <div className={classes.item}> */}
      {/*                 <div>1.</div> */}
      {/*  */}
      {/*                 <div className={classes.itemImage}> */}
      {/*                   <img src="/assets/form-icon.svg" /> */}
      {/*                 </div> */}
      {/*  */}
      {/*                 <Typography align="center" variant="subtitle1"> */}
      {/*                   Submit your site information detailing location and property characteristics */}
      {/*                 </Typography> */}
      {/*               </div> */}
      {/*             </Grid> */}
      {/*  */}
      {/*             <Grid item xs={12} md={4}> */}
      {/*               <div className={classes.item}> */}
      {/*                 <div>2.</div> */}
      {/*  */}
      {/*                 <div className={classes.itemImage}> */}
      {/*                   <img src="/assets/eye-icon.svg" /> */}
      {/*                 </div> */}
      {/*  */}
      {/*                 <Typography align="center" variant="subtitle1"> */}
      {/*                   Our system uses your site information, matched with demographics to compare your property with our database of */}
      {/*                   retailers and restaurants */}
      {/*                 </Typography> */}
      {/*               </div> */}
      {/*             </Grid> */}
      {/*  */}
      {/*             <Grid item xs={12} md={4}> */}
      {/*               <div className={classes.item}> */}
      {/*                 <div>3.</div> */}
      {/*  */}
      {/*                 <div className={classes.itemImage}> */}
      {/*                   <img src="/assets/chart-icon.svg" /> */}
      {/*                 </div> */}
      {/*  */}
      {/*                 <Typography align="center" variant="subtitle1"> */}
      {/*                   Review detailed results of the retailers and restaurants that best fit your site's demographic and site */}
      {/*                   characteristics */}
      {/*                 </Typography> */}
      {/*               </div> */}
      {/*             </Grid> */}
      {/*           </Grid> */}
      {/*  */}
      {/*           <Button */}
      {/*             color="primary" */}
      {/*             variant="contained" */}
      {/*             size="large" */}
      {/*             className={`${classes.button} ${classes.button2}`} */}
      {/*             component={Link} */}
      {/*             to="/properties" */}
      {/*           > */}
      {/*             Get Started! */}
      {/*           </Button> */}
      {/*         </Container> */}
      {/*  */}
      {/*         <div className={`${classes.background} ${classes.backgroundImage2}`} /> */}
      {/*       </section> */}
      {/*  */}
      {/*       <Footer /> */}
    </Fragment>
  );
}

export default withStyles(styles)(Home);
