import React from "react";
import { Route, Redirect } from "react-router-dom";

import Spinner from "components/Spinner";
import { useAuth } from "utils/auth";

export default ({ component: Component, userType, componentProps, ...rest }) => {
  const auth = useAuth();

  const checkUserType = () =>
    Array.isArray(userType)
      ? userType.some((type) => auth.user.user_types.indexOf(type) !== -1)
      : auth.user.user_types.indexOf(userType) !== -1;

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.loading ? (
          <Spinner fullScreen size={24} />
        ) : auth.user && checkUserType() ? (
          <Component {...componentProps} {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};
