import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";

import TextField from "components/TextField";
import AppBar from "components/AppBar";
import Footer from "components/Footer";
import Container from "components/Container";
import Typography from "components/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedSelect from "components/OutlinedSelect";
import Spinner from "components/Spinner";
import ForgotPasswordModal from "../Login/children/ForgotPasswordModal";
import { useAuth } from "utils/auth";
import { parseQueryString } from "utils/qs";

const styles = (theme) => ({
  container: {
    color: "inherit",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  },
  paper: {
    backgroundColor: theme.palette.gray.main,
    padding: theme.spacing(5)
  },
  textField: {
    marginTop: theme.spacing(1)
  }
});

const VALIDATION_RULES = {
  first_name: {
    required: true
  },
  last_name: {
    required: true
  },
  email: {
    required: true
  },
  password: {
    required: true,
    minLength: {
      value: 8,
      message: "Must be 8 characters mimumum"
    }
  },
  password2: {
    required: true,
    minLength: {
      value: 8,
      message: "Must be 8 characters mimumum"
    }
  }
};

function Register({ classes }) {
  const { register, errors, handleSubmit, watch, setValue, reset, control } = useForm({
    mode: "onBlur",
    defaultValues: { user_types: [] }
  });
  const data = watch();

  const auth = useAuth();
  const location = useLocation();

  const [initialising, setInitialising] = React.useState(true);
  const [initialisationError, setInitialisationError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [inviteCode, setInviteCode] = React.useState(null);
  const [inviteCodeUserType, setInviteCodeUserType] = React.useState(null);
  const [userTypesHash, setUserTypesHash] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [errorEmailExists, setErrorEmailExists] = React.useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = React.useState(false);

  const handleSignUp = async (data) => {
    setLoading(true);

    try {
      await auth.register({ invite_code: inviteCode, ...data });
    } catch (e) {
      setError(e.message);
      setErrorEmailExists(e.data && e.data.emailExists);
      setLoading(false);
      return;
    }

    setError(null);
    setSuccess(true);
    setLoading(false);
  };

  const init = async (code) => {
    let userType, userTypes;

    try {
      const data = await auth.verifyInviteCode(code);
      userType = data.user_type;
      userTypes = data.user_types;
    } catch (e) {
      setInitialisationError(e.message);
      return;
    }

    const userTypesHash = userTypes.reduce((hash, { name, public_name }) => ({ ...hash, [name]: public_name }), {});
    setUserTypesHash(userTypesHash);

    setTimeout(() => {
      // Workaround for the issue when you can't use setValue before the field is registered
      setValue("user_types", userType === "any" ? [userTypes[0].name] : [userType], {
        shouldValidate: true,
        shouldDirty: true
      });
    }, 500);

    setInviteCodeUserType(userType);
    setInitialising(false);
  };

  const toggleForgotPasswordModal = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal);
  };

  React.useEffect(() => {
    const qs = parseQueryString(location.search);
    setInviteCode(qs.code);

    const defaultValues = ["first_name", "last_name", "email"].reduce(
      (hash, key) => {
        hash[key] = key in qs ? qs[key] : undefined;
        return hash;
      },
      { user_types: [] }
    );

    reset(defaultValues);

    init(qs.code);
  }, []);

  return (
    <Fragment>
      <AppBar />

      <Container className={classes.container} maxWidth="sm">
        {initialising ? (
          initialisationError ? (
            <Alert severity="error">{initialisationError}</Alert>
          ) : (
            <Spinner />
          )
        ) : (
          <Paper elevation={0} className={classes.paper}>
            <form onSubmit={handleSubmit(handleSignUp)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography color="inherit" align="center" variant="h3" marked="center">
                    Sign Up
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="first_name"
                    inputRef={register(VALIDATION_RULES.first_name)}
                    error={!!errors.first_name}
                    helperText={errors.first_name && errors.first_name.message}
                    label="First Name"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    empty={!data.first_name}
                    required
                    fullWidth
                    autoFocus={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="last_name"
                    inputRef={register(VALIDATION_RULES.last_name)}
                    error={!!errors.last_name}
                    helperText={errors.last_name && errors.last_name.message}
                    label="Last Name"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    empty={!data.last_name}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    inputRef={register(VALIDATION_RULES.email)}
                    error={!!errors.email}
                    helperText={errors.email && errors.email.message}
                    label="Email"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    type="email"
                    color="secondary"
                    empty={!data.email}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="password"
                    inputRef={register(VALIDATION_RULES.password)}
                    error={!!errors.password}
                    helperText={errors.password && errors.password.message}
                    label="Password"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    type="password"
                    color="secondary"
                    empty={!data.password}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="password2"
                    inputRef={register(VALIDATION_RULES.password2)}
                    error={!!errors.password2}
                    helperText={errors.password2 && errors.password2.message}
                    label="Confirm Password"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    type="password"
                    color="secondary"
                    empty={!data.password2}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="user_types"
                    value={data.user_types}
                    rules={{ validate: (value) => !!value.length }}
                    control={control}
                    render={({ onChange, onBlur, value, name, ref }) => (
                      <OutlinedSelect
                        name={name}
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                        label="User Role"
                        margin="normal"
                        color="secondary"
                        error={!!errors.user_types}
                        helperText={errors.user_types && errors.user_types.message}
                        empty={!data.user_types || !data.user_types.length}
                        fullWidth
                        required
                        selectProps={{
                          multiple: true,
                          renderValue: (selected) =>
                            Array.isArray(selected) ? selected.map((name) => userTypesHash[name]).join(", ") : ""
                        }}
                        disabled={inviteCodeUserType !== "any"}
                      >
                        {Object.keys(userTypesHash).map((name) => (
                          <MenuItem value={name} key={name}>
                            <Checkbox checked={data.user_types.indexOf(name) > -1} />
                            {userTypesHash[name]}
                          </MenuItem>
                        ))}
                      </OutlinedSelect>
                    )}
                  />
                </Grid>

                {error && (
                  <Grid item xs={12}>
                    <Alert severity="error">
                      {errorEmailExists ? (
                        <Fragment>
                          The email you have entered is already in use. If you cannot remember your password,{" "}
                          <a href="#" onClick={toggleForgotPasswordModal}>
                            click here to reset it
                          </a>
                        </Fragment>
                      ) : (
                        error
                      )}
                    </Alert>
                  </Grid>
                )}

                {success && (
                  <Grid item xs={12}>
                    <Alert severity="success">Success! Please check your inbox in order to complete registration</Alert>
                  </Grid>
                )}

                <Grid item xs={12}>
                  {!!loading ? (
                    <Spinner />
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      size="large"
                      type="submit"
                      disabled={loading || success}
                    >
                      Sign Up
                    </Button>
                  )}
                </Grid>
              </Grid>
            </form>
          </Paper>
        )}
      </Container>

      {showForgotPasswordModal && (
        <ForgotPasswordModal
          defaultEmail={data.email}
          onCancel={toggleForgotPasswordModal}
          onSuccess={toggleForgotPasswordModal}
        />
      )}
    </Fragment>
  );
}

export default withStyles(styles)(Register);
