import React, { useEffect } from "react";
import TextField from "components/TextField";
import MUIAutocomplete from "@material-ui/lab/Autocomplete";
import { Controller } from "react-hook-form";

export default function ControlledAutoComplete({ rules, onChange: ignored, control, name, options, label, ...props1 }) {
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <MUIAutocomplete
          autoHighlight={true}
          id="autoComplete"
          options={options}
          getOptionLabel={(option) => option}
          renderInput={(params) => {
            return <TextField {...params} label={label || "State"} variant="outlined" {...props1} />;
          }}
          onChange={(e, data) => onChange(data)}
          {...props}
        />
      )}
      defaultValue=""
      onChange={([, data]) => data}
      name={name}
      control={control}
      rules={rules}
    />
  );
}
