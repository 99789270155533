import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AccountCircleOutlined from "@material-ui/icons/AccountCircleOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import Card from "@material-ui/core/Card";

const styles = (theme) => ({
  root: {
    minWidth: 275,
    padding: 16,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      display: "block"
    }
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  title: {
    fontFamily: ["Raleway", "Roboto", "sans-serif"],
    color: theme.palette.purple.main,
    fontSize: 32,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  crumbContainer: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center"
    }
  },
  crumbName: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Raleway",
    fontSize: 14,
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2)
    }
  },
  crumbCarrot: {
    margin: `0 ${theme.spacing(1)}px`,
    fontWeight: 300
  },
  subtitle: {
    width: "100%",
    fontSize: 16
  }
});

function PageTitle({ children, classes, crumbs = [], subtitle }) {
  const formatCrumbs = (crumbs) => {
    const crumbCarrot = " > ";
    return crumbs.map((crumb, idx) => {
      return (
        <Typography key={crumb} className={classes.crumbName}>
          {crumb === "Profile" ? <AccountCircleOutlined /> : crumb === "Admin" ? <SettingsIcon /> : crumb}
          {idx === crumbs.length - 1 ? "" : <span className={classes.crumbCarrot}>{crumbCarrot}</span>}
        </Typography>
      );
    });
  };

  return (
    <Card className={classes.root}>
      <div className={classes.flex}>
        <Typography className={classes.title} align="left" variant="h2">
          {children}
        </Typography>
        <span className={classes.crumbContainer}>{formatCrumbs(crumbs)}</span>
      </div>

      {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
    </Card>
  );
}

export default withStyles(styles)(PageTitle);
