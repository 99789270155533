import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  inputLabel: {
    "&$inputLabelFocused": {
      color: theme.palette.primary.main
    }
  },
  inputLabelSecondary: {
    "&$inputLabelFocused": {
      color: theme.palette.secondary.main
    }
  },
  inputLabelFocused: {},
  outlinedInput: {
    "&$outlinedInputFocused $notchedOutline": {
      borderColor: theme.palette.primary.main
    },
    "&$outlinedInputFocused $notchedOutlineSecondary": {
      borderColor: theme.palette.secondary.main
    }
  },
  outlinedInputFocused: {},
  notchedOutline: {},
  notchedOutlineSecondary: {}
});

class OutlinedSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labelWidth: 0
    };
  }

  componentDidMount() {
    if (this.labelRef && !this.state.labelWidth) {
      const { offsetWidth } = ReactDOM.findDOMNode(this.labelRef);
      if (offsetWidth) {
        this.setState({ labelWidth: offsetWidth });
      }
    }
  }

  setRef = (ref) => (this.labelRef = ref);

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      classes,
      name,
      label,
      value,
      children,
      disabled,
      onChange,
      helperText,
      color,
      error = false,
      required,
      selectProps,
      leftPadding
    } = this.props;

    return (
      <FormControl error={error} variant="outlined" fullWidth classes={{ root: classes.formControl }}>
        <InputLabel
          classes={{
            root: color === "secondary" ? classes.inputLabelSecondary : classes.inputLabel,
            focused: classes.inputLabelFocused
          }}
          ref={this.setRef}
          htmlFor={`outlined-${name}`}
          error={error}
          required={required}
        >
          {label}
        </InputLabel>
        <Select
          {...selectProps}
          disabled={disabled}
          value={value || ""}
          onChange={onChange}
          input={
            <OutlinedInput
              classes={{
                root: classes.outlinedInput,
                focused: classes.outlinedInputFocused,
                notchedOutline: color === "secondary" ? classes.notchedOutlineSecondary : classes.notchedOutline
              }}
              labelWidth={this.state.labelWidth}
              name={name}
              id={`outlined-${name}`}
            />
          }
        >
          {children}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
}

OutlinedSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(styles)(OutlinedSelect);
