import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";

import Modal from "components/Modal";
import Typography from "components/Typography";
import { useGlobalUI } from "context/globalUI";

export default function ConfirmDeleteContactModal() {
  const {
    confirmDelete: { modalOpen, closeModal, deleteMethod, itemToDelete }
  } = useGlobalUI();

  const handleCancel = () => closeModal();

  const handleDelete = () => {
    deleteMethod(itemToDelete);
    closeModal();
  };

  if (!modalOpen) {
    return null;
  }

  return (
    <Modal
      open={true}
      title={"Are You Sure?"}
      actions={
        <Fragment>
          <Button variant="text" onClick={handleCancel}>
            Cancel
          </Button>

          <Button color="primary" variant="text" onClick={handleDelete}>
            Delete
          </Button>
        </Fragment>
      }
    >
      <Typography>Please confirm this action before deleting.</Typography>
    </Modal>
  );
}
