import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

import ContactCard from "../children/ContactCard";
import sharedStyles from "../styles";
import { useGlobalUI } from "context/globalUI";
import { useAPI, RESOURCES } from "utils/api";
import { useAuth } from "utils/auth";
import Spinner from "components/Spinner";
import AppBar from "components/AppBar";
import Container from "components/Container";
import Typography from "components/Typography";
import PageTitle from "components/PageTitle";

const styles = (theme) => ({
  ...sharedStyles(theme),
  description: {
    padding: theme.spacing(3),
    margin: theme.spacing(0, 0, 3),
    "& > *": {
      fontSize: 16,
      fontWeight: theme.typography.fontWeightLight,
      letterSpacing: ".1em"
    }
  },
  button: {
    marginBottom: theme.spacing(6)
  },
  center: {
    textAlign: "center",
    margin: theme.spacing(0, 0, 3)
  }
});

const validatePhone = (value) => {
  return !value || /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/.test(value);
};

function Contacts({ classes }) {
  const [loading, setLoading] = React.useState(true);
  const [contacts, setContacts] = React.useState([]);

  const api = useAPI();
  const { snackbar } = useGlobalUI();

  React.useEffect(() => {
    async function load() {
      setLoading(true);

      try {
        const contacts = await api.callWithAuth("GET", RESOURCES.accountContacts);
        setContacts(contacts);
      } catch (e) {
        snackbar.open({ message: e.message, color: "error" });
        return;
      } finally {
        setLoading(false);
      }
    }

    load();
  }, []);

  const handleAddContact = () => {
    const contactsClone = contacts.slice();
    contactsClone.unshift({ id: null, phone_is_cell: true });
    setContacts(contactsClone);
  };

  const handleContactUpdate = (id) => (updatedContact) => {
    const contactsClone = contacts.slice();
    const idx = contactsClone.findIndex((contact) => contact.id === id);
    contactsClone[idx] = { ...contactsClone[idx], ...updatedContact };
    setContacts(contactsClone);
  };

  const handleContactDelete = (id) => () => {
    const contactsClone = contacts.slice();
    const idx = contactsClone.findIndex((contact) => contact.id === id);
    contactsClone.splice(idx, 1);
    setContacts(contactsClone);
  };

  const newContactIsBeingAdded = React.useMemo(() => {
    return contacts.some(({ id }) => !id);
  }, [contacts]);

  return (
    <Fragment>
      <AppBar />

      <Container className={classes.container} maxWidth="md">
        {/* <Typography  align="center" variant="h2" gutterBottom>
          My Contacts
        </Typography> */}
        <PageTitle crumbs={["Profile", "My Contacts"]}>My Contacts</PageTitle>

        <Card>
          <Typography className={classes.description} variant="body1" align="center">
            Save multiple sets of contact information to make setting up your properties or requiremnets easier.
          </Typography>

          <div className={classes.center}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              startIcon={<AddIcon />}
              onClick={handleAddContact}
              disabled={newContactIsBeingAdded}
              className={classes.button}
            >
              Add Contact
            </Button>
          </div>

          {loading ? (
            <Spinner />
          ) : (
            contacts.map((contact) => (
              <ContactCard
                key={contact.id || -1}
                contact={contact}
                onContactUpdate={handleContactUpdate(contact.id)}
                onContactDelete={handleContactDelete(contact.id)}
              />
            ))
          )}
        </Card>
      </Container>
    </Fragment>
  );
}

export default withStyles(styles)(Contacts);
