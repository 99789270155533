import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

import { overriddenUserId } from "utils/api";

const { REACT_APP_GA_TRACKING_ID: gaTrackingId } = process.env;
const isGAEnabled = !!gaTrackingId && !overriddenUserId; // Disable Analytics for "Login As" mode

let gtag = function () {};

if (!isGAEnabled) {
  console.log("GoogleAnalytics is disabled");
} else if (window.gtag) {
  gtag = window.gtag;
  gtag("config", gaTrackingId);
}

const ctx = React.createContext();

class RouteTrackerComponent extends Component {
  componentDidMount() {
    if (!isGAEnabled) {
      return;
    }

    this.logPageChange(this.props.location.pathname, this.props.location.search);
  }

  componentDidUpdate({ location: prevLocation }) {
    if (!isGAEnabled) {
      return;
    }

    const {
      location: { pathname, search }
    } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname, search = "") {
    if (!isGAEnabled) {
      return;
    }

    const path = pathname + search;
    const { location } = window;

    gtag("config", gaTrackingId, {
      page_path: path,
      page_location: `${location.origin}${path}`,
      ...this.props.options
    });
  }

  render() {
    return null;
  }
}

RouteTrackerComponent.defaultProps = {
  options: {}
};

RouteTrackerComponent.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }).isRequired,
  options: PropTypes.shape({})
};

const RouteTracker = () => <Route component={RouteTrackerComponent} />;

class Provider extends Component {
  _userId = null;

  trackEvent = (name, data) => {
    gtag("event", name, data);
  };

  setUserId = (userId) => {
    if (this._userId !== userId) {
      this._userId = userId;

      gtag("config", gaTrackingId, {
        user_id: userId
      });
      gtag("set", { user_id: userId });
    }
  };

  render() {
    return <ctx.Provider value={{ trackEvent: this.trackEvent, setUserId: this.setUserId }}>{this.props.children}</ctx.Provider>;
  }
}

Provider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired
};

/**
 * HOC which provides wrapped component with analytics object
 */
export const withAnalytics = (WrappedComponent) =>
  class AnalyticsComponent extends React.PureComponent {
    render() {
      return <ctx.Consumer>{(providerValue) => <WrappedComponent {...this.props} analytics={providerValue} />}</ctx.Consumer>;
    }
  };

export const useAnalytics = () => {
  return React.useContext(ctx);
};

export default {
  Provider,
  RouteTracker
};
