import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiCard from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(4)
  }
}));

export default function Card({ children, customStyle }) {
  const classes = useStyles();

  return (
    <MuiCard className={classes.root}>
      <CardContent style={{ ...customStyle }}>{children}</CardContent>
    </MuiCard>
  );
}
