export default (theme) => ({
  container: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  },
  helperText: {
    maxWidth: 600,
    margin: "0 auto"
  },
  form: {
    marginTop: theme.spacing(4),
    maxWidth: 800,
    margin: "0 auto"
  },
  textField: {
    marginBottom: theme.spacing(3)
  }
});
