import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import ProtectedRoute from "components/HOC/ProtectedRoute";
import AnonymousRoute from "components/HOC/AnonymousRoute";
import Reports from "./pages/Reports";
import Account from "./pages/Account";
import AccountProfile from "./pages/Account/pages/Profile";
import AccountContacts from "./pages/Account/pages/Contacts";
import AccountSubscription from "./pages/Account/pages/Subscription";
import AccountPreferences from "./pages/Account/pages/Preferences";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import PageNotFound from "components/404";
import { useGlobalUI } from "context/globalUI";
import { useAuth, USER_TYPES } from "utils/auth";
import { RESOURCES } from "utils/api";

function MainApp() {
  const { deviceScreen } = useGlobalUI();
  const { user } = useAuth();

  useEffect(() => {
    deviceScreen.getDeviceWidth();
    window.addEventListener("resize", deviceScreen.getDeviceWidth);

    return function cleanup() {
      window.removeEventListener("resize", deviceScreen.getDeviceWidth);
    };
  }, []);

  const homeURL = React.useMemo(() => {
    if (!user || !user.user_types || !user.user_types.length) {
      return "/";
    }

    if (user.user_types.indexOf(USER_TYPES.Admin) !== -1) {
      return "/admin/users";
    }

    if (user.user_types.indexOf(USER_TYPES.Broker) !== -1) {
      return "/properties";
    }

    if (user.user_types.indexOf(USER_TYPES.Retailer) !== -1) {
      return "/retailers";
    }
  }, [user]);

  return (
    <Switch style={{ backgroundColor: "red" }}>
      <ProtectedRoute exact userType={["broker", "retailer"]} path="/reports" component={Reports} />

      <ProtectedRoute exact userType={["broker", "admin", "retailer"]} path="/account" component={Account} />
      <ProtectedRoute exact userType={["broker", "admin", "retailer"]} path="/account/profile" component={AccountProfile} />
      <ProtectedRoute exact userType={["broker", "admin", "retailer"]} path="/account/contacts" component={AccountContacts} />
      <ProtectedRoute
        exact
        userType={["broker", "admin", "retailer"]}
        path="/account/preferences"
        component={AccountPreferences}
      />
      <ProtectedRoute
        exact
        userType={["broker", "admin", "retailer"]}
        path="/account/subscription"
        component={AccountSubscription}
      />

      <ProtectedRoute
        exact
        userType={["broker", "admin", "retailer"]}
        path="/"
        component={Redirect}
        componentProps={{ to: homeURL }}
      />
      <AnonymousRoute exact path="/login" component={Login} />
      <AnonymousRoute exact path="/register" component={Register} />

      <Route component={PageNotFound} />
    </Switch>
  );
}

export default MainApp;
